ul {
  -webkit-padding-start: 0;
  list-style: none;
  padding-inline-start: 0
}

:root {
  --red: #F4392C;
  --link: #297AD8;
  --link-lighten5: rgba(41, 122, 216, 0.05);
  --blue1: #3150A5;
  --blue1-lighten7: rgba(48, 80, 166, 0.07);
  --blue2-lighten15: rgba(115, 117, 165, 0.15);
  --blue2-lighten20: rgba(115, 117, 165, 0.20);
  --kgi-key: #B040A5;
  --kpi-key: #F59331;
  --grey-lighten70: rgba(56, 57, 71, 0.70);
}

.centered-container-1056 {
  margin: 0 auto;
  max-width: 1056px
}

.indicator-tree-table .icon-direction {
  color: var(--blue2-lighten20);
}

.indicator-tree-table .icon-direction.is-active {
  color: var(--link);
}

.indicator-tree-table {
  /* background-color: #fff; */
  border-spacing: 0;
  height: 884px;
  border-collapse: separate;
}

.indicator-tree-table th,
.indicator-tree-table td {
  padding: 0;
  vertical-align: top;
}

.indicator-tree-table-td-top {
  background-color: var(--blue1-lighten7);
  border: 1px solid var(--blue2-lighten20);
  border-bottom-color: var(--blue2-lighten15);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 5px;
  width: 48%;
  height: 80px;
}

.indicator-tree-table-td-top.is-active {
  border-top: 1px solid var(--link);
  border-left: 1px solid var(--link);
  border-right: 1px solid var(--link);
}

.indicator-tree-table-td-top.is-target {
  background-color: #e7ecf6;
}

.indicator-tree-table-td-top-inner::before {
  content: '';
  position: absolute;
  width: 6px;
  border-radius: 5px;
  top: 19px;
  bottom: 19px;
  left: 21px;
  background-color: var(--link);
}

.indicator-tree-table-td-center {
  border: 1px solid var(--link);
  border-bottom: none;
  border-top: none
}

.indicator-tree-table-td-bottom {
  border: 1px solid var(--blue2-lighten20);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top: none;
  overflow: hidden;
}

.indicator-tree-table-td-bottom.is-active {
  border-left: 1px solid var(--link);
  border-right: 1px solid var(--link);
  border-bottom: 1px solid var(--link);
}

.indicator-tree-table-td-bottom.is-target {
  background-color: var(--link-lighten5);
}

.indicator-tree-item {
  height: 805px;
  /* width: 339px */
}

.indicator-tree-item2 {
  height: 400px;
  /* width: 339px */
}

.indicator-tree-item3 {
  height: 284px;
}

/* .indicator-tree-name {
  color: var(--blue1);
  font-weight: 700;
  text-align: left
} */

/* .indicator-tree-desc {
  color: var(--red);
  font-size: 14px;
  text-align: left
} */

.indicator-tree-content {
  /* background: #fff; */
  height: 100%;
  overflow: auto
}

.inversion-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1)
}

.tree--treeRoot {
  border: none;
  box-sizing: border-box;
  height: 100%;
  margin-top: 0;
}

.tree--treeRoot [role="listitem"].is-dropped {
  animation: 1.5s linear 1 alternate itemDropped;
}

@keyframes itemDropped {
  0% {
    background-color: var(--link-lighten5);
  }

  100% {
    background-color: transparent;
  }
}

.tree--draggingSource {
  opacity: .3
}

.tree--dropTarget {
  background-color: var(--link-lighten5);
}

.tree--placeholderContainer {
  position: relative
}

.tree--placeholderRoot {
  background-color: var(--link);
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.tree--node--root {
  -webkit-padding-end: 8px;
  align-items: center;
  border-bottom: 1px solid var(--blue2-lighten15);
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 40px;
  padding-inline-end: 8px
}

.tree--node--arrow {
  margin-left: 8px;
  cursor: pointer;
  height: 16px;
  justify-content: center;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: -webkit-transform .1s linear;
  transition: transform .1s linear;
  transition: transform .1s linear, -webkit-transform .1s linear;
  width: 16px
}

.tree--node--arrow, .tree--node--arrow>div {
  align-items: center;
  display: flex
}

.tree--node--arrow>div>svg {
  color: var(--grey-lighten70);
}

.tree--node--arrow.is-open {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.tree--node--filetype {
  display: flex
}

.tree--node--label {
  -webkit-padding-start: 8px;
  padding-inline-start: 8px
}

.tree--node--filetypeKgi {
  margin-left: 10px;
  font-size: 32px;
  color: var(--kgi-key);
  line-height: 1;
  font-family: icon !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

.tree--node--filetypeKgi:before {
  content: '\e91b';
}

.tree--node--labelKgi {
  color: var(--grey-lighten70);
  font-size: 14px;
  line-height: 1.8;
  font-weight: 500;
  -webkit-padding-start: 6px;
  padding-inline-start: 6px
}

.tree--node--filetypeKpi {
  margin-left: 10px;
  font-size: 32px;
  color: var(--kpi-key);
  line-height: 1;
  font-family: icon !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

.tree--node--filetypeKpi:before {
  content: '\e91c';
}

.tree--node--labelKpi {
  color: var(--grey-lighten70);
  font-size: 14px;
  line-height: 1.8;
  font-weight: 500;
  -webkit-padding-start: 6px;
  padding-inline-start: 6px
}

.tree--dragpreview--root {
  align-items: "center";
  background-color: var(--link);
  border-radius: 4px;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, .25), 0 0 0 1px rgba(0, 0, 0, .08);
  color: #fff;
  display: inline-grid;
  font-size: 14px;
  gap: 8px;
  grid-template-columns: auto auto;
  padding: 4px 8px;
  pointer-events: none
}

.tree--dragpreview--icon, .tree--dragpreview--label {
  align-items: center;
  display: flex
}

.tree--praceholder--root {
  background-color: var(--link);
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}