@charset "UTF-8";
:root {
  --animate-duration: 150ms;
}

.red {
  background-color: #F4392C !important;
  border-color: #F4392C !important;
}

.red--text {
  color: #F4392C !important;
  caret-color: #F4392C !important;
}

.border-red {
  border-color: #F4392C !important;
}

.red.lighten-90 {
  background-color: rgba(255, 77, 91, 0.9) !important;
  border-color: rgba(255, 77, 91, 0.9) !important;
}

.red--text.text--lighten-90 {
  color: rgba(255, 77, 91, 0.9) !important;
  caret-color: rgba(255, 77, 91, 0.9) !important;
}

.border-red-lighten-90 {
  border-color: rgba(255, 77, 91, 0.9) !important;
}

.red.lighten-70 {
  background-color: rgba(255, 77, 91, 0.7) !important;
  border-color: rgba(255, 77, 91, 0.7) !important;
}

.red--text.text--lighten-70 {
  color: rgba(255, 77, 91, 0.7) !important;
  caret-color: rgba(255, 77, 91, 0.7) !important;
}

.border-red-lighten-70 {
  border-color: rgba(255, 77, 91, 0.7) !important;
}

.red2 {
  background-color: #F7554A !important;
  border-color: #F7554A !important;
}

.red2--text {
  color: #F7554A !important;
  caret-color: #F7554A !important;
}

.border-red2 {
  border-color: #F7554A !important;
}

.red3 {
  background-color: #F23939 !important;
  border-color: #F23939 !important;
}

.red3--text {
  color: #F23939 !important;
  caret-color: #F23939 !important;
}

.border-red3 {
  border-color: #F23939 !important;
}

.orange {
  background-color: #FF9000 !important;
  border-color: #FF9000 !important;
}

.orange--text {
  color: #FF9000 !important;
  caret-color: #FF9000 !important;
}

.border-orange {
  border-color: #FF9000 !important;
}

.orange.lighten-90 {
  background-color: rgba(255, 144, 0, 0.9) !important;
  border-color: rgba(255, 144, 0, 0.9) !important;
}

.orange--text.text--lighten-90 {
  color: rgba(255, 144, 0, 0.9) !important;
  caret-color: rgba(255, 144, 0, 0.9) !important;
}

.border-orange-lighten-90 {
  border-color: rgba(255, 144, 0, 0.9) !important;
}

.orange.lighten-70 {
  background-color: rgba(255, 144, 0, 0.7) !important;
  border-color: rgba(255, 144, 0, 0.7) !important;
}

.orange--text.text--lighten-70 {
  color: rgba(255, 144, 0, 0.7) !important;
  caret-color: rgba(255, 144, 0, 0.7) !important;
}

.border-orange-lighten-70 {
  border-color: rgba(255, 144, 0, 0.7) !important;
}

.green {
  background-color: #00B295 !important;
  border-color: #00B295 !important;
}

.green--text {
  color: #00B295 !important;
  caret-color: #00B295 !important;
}

.border-green {
  border-color: #00B295 !important;
}

.green.lighten-90 {
  background-color: rgba(0, 178, 149, 0.9) !important;
  border-color: rgba(0, 178, 149, 0.9) !important;
}

.green--text.text--lighten-90 {
  color: rgba(0, 178, 149, 0.9) !important;
  caret-color: rgba(0, 178, 149, 0.9) !important;
}

.border-green-lighten-90 {
  border-color: rgba(0, 178, 149, 0.9) !important;
}

.green.lighten-70 {
  background-color: rgba(0, 178, 149, 0.7) !important;
  border-color: rgba(0, 178, 149, 0.7) !important;
}

.green--text.text--lighten-70 {
  color: rgba(0, 178, 149, 0.7) !important;
  caret-color: rgba(0, 178, 149, 0.7) !important;
}

.border-green-lighten-70 {
  border-color: rgba(0, 178, 149, 0.7) !important;
}

.green2 {
  background-color: #009E77 !important;
  border-color: #009E77 !important;
}

.green2--text {
  color: #009E77 !important;
  caret-color: #009E77 !important;
}

.border-green2 {
  border-color: #009E77 !important;
}

.green2.light {
  background-color: #00BA8C !important;
  border-color: #00BA8C !important;
}

.green2--text.text--light {
  color: #00BA8C !important;
  caret-color: #00BA8C !important;
}

.border-green2-light {
  border-color: #00BA8C !important;
}

.green2.lighten-90 {
  background-color: rgba(0, 158, 119, 0.9) !important;
  border-color: rgba(0, 158, 119, 0.9) !important;
}

.green2--text.text--lighten-90 {
  color: rgba(0, 158, 119, 0.9) !important;
  caret-color: rgba(0, 158, 119, 0.9) !important;
}

.border-green2-lighten-90 {
  border-color: rgba(0, 158, 119, 0.9) !important;
}

.green2.lighten-70 {
  background-color: rgba(0, 158, 119, 0.7) !important;
  border-color: rgba(0, 158, 119, 0.7) !important;
}

.green2--text.text--lighten-70 {
  color: rgba(0, 158, 119, 0.7) !important;
  caret-color: rgba(0, 158, 119, 0.7) !important;
}

.border-green2-lighten-70 {
  border-color: rgba(0, 158, 119, 0.7) !important;
}

.green2.lighten-50 {
  background-color: rgba(0, 158, 119, 0.5) !important;
  border-color: rgba(0, 158, 119, 0.5) !important;
}

.green2--text.text--lighten-50 {
  color: rgba(0, 158, 119, 0.5) !important;
  caret-color: rgba(0, 158, 119, 0.5) !important;
}

.border-green2-lighten-50 {
  border-color: rgba(0, 158, 119, 0.5) !important;
}

.green2.lighten-40 {
  background-color: rgba(0, 158, 119, 0.4) !important;
  border-color: rgba(0, 158, 119, 0.4) !important;
}

.green2--text.text--lighten-40 {
  color: rgba(0, 158, 119, 0.4) !important;
  caret-color: rgba(0, 158, 119, 0.4) !important;
}

.border-green2-lighten-40 {
  border-color: rgba(0, 158, 119, 0.4) !important;
}

.green2.lighten-30 {
  background-color: rgba(0, 158, 119, 0.3) !important;
  border-color: rgba(0, 158, 119, 0.3) !important;
}

.green2--text.text--lighten-30 {
  color: rgba(0, 158, 119, 0.3) !important;
  caret-color: rgba(0, 158, 119, 0.3) !important;
}

.border-green2-lighten-30 {
  border-color: rgba(0, 158, 119, 0.3) !important;
}

.green2.lighten-20 {
  background-color: rgba(0, 158, 119, 0.2) !important;
  border-color: rgba(0, 158, 119, 0.2) !important;
}

.green2--text.text--lighten-20 {
  color: rgba(0, 158, 119, 0.2) !important;
  caret-color: rgba(0, 158, 119, 0.2) !important;
}

.border-green2-lighten-20 {
  border-color: rgba(0, 158, 119, 0.2) !important;
}

.green2.lighten-6 {
  background-color: rgba(0, 158, 119, 0.06) !important;
  border-color: rgba(0, 158, 119, 0.06) !important;
}

.green2--text.text--lighten-6 {
  color: rgba(0, 158, 119, 0.06) !important;
  caret-color: rgba(0, 158, 119, 0.06) !important;
}

.border-green2-lighten-6 {
  border-color: rgba(0, 158, 119, 0.06) !important;
}

.green2.lighten-5 {
  background-color: rgba(0, 158, 119, 0.05) !important;
  border-color: rgba(0, 158, 119, 0.05) !important;
}

.green2--text.text--lighten-5 {
  color: rgba(0, 158, 119, 0.05) !important;
  caret-color: rgba(0, 158, 119, 0.05) !important;
}

.border-green2-lighten-5 {
  border-color: rgba(0, 158, 119, 0.05) !important;
}

.grey {
  background-color: #383947 !important;
  border-color: #383947 !important;
}

.grey--text {
  color: #383947 !important;
  caret-color: #383947 !important;
}

.border-grey {
  border-color: #383947 !important;
}

.grey.lighten-70 {
  background-color: rgba(56, 57, 71, 0.7) !important;
  border-color: rgba(56, 57, 71, 0.7) !important;
}

.grey--text.text--lighten-70 {
  color: rgba(56, 57, 71, 0.7) !important;
  caret-color: rgba(56, 57, 71, 0.7) !important;
}

.border-grey-lighten-70 {
  border-color: rgba(56, 57, 71, 0.7) !important;
}

.grey.lighten-45 {
  background-color: rgba(56, 57, 71, 0.45) !important;
  border-color: rgba(56, 57, 71, 0.45) !important;
}

.grey--text.text--lighten-45 {
  color: rgba(56, 57, 71, 0.45) !important;
  caret-color: rgba(56, 57, 71, 0.45) !important;
}

.border-grey-lighten-45 {
  border-color: rgba(56, 57, 71, 0.45) !important;
}

.grey.lighten-35 {
  background-color: rgba(56, 57, 71, 0.35) !important;
  border-color: rgba(56, 57, 71, 0.35) !important;
}

.grey--text.text--lighten-35 {
  color: rgba(56, 57, 71, 0.35) !important;
  caret-color: rgba(56, 57, 71, 0.35) !important;
}

.border-grey-lighten-35 {
  border-color: rgba(56, 57, 71, 0.35) !important;
}

.grey.lighten-10 {
  background-color: rgba(56, 57, 71, 0.1) !important;
  border-color: rgba(56, 57, 71, 0.1) !important;
}

.grey--text.text--lighten-10 {
  color: rgba(56, 57, 71, 0.1) !important;
  caret-color: rgba(56, 57, 71, 0.1) !important;
}

.border-grey-lighten-10 {
  border-color: rgba(56, 57, 71, 0.1) !important;
}

.grey.lighten-2 {
  background-color: rgba(56, 57, 71, 0.02) !important;
  border-color: rgba(56, 57, 71, 0.02) !important;
}

.grey--text.text--lighten-2 {
  color: rgba(56, 57, 71, 0.02) !important;
  caret-color: rgba(56, 57, 71, 0.02) !important;
}

.border-grey-lighten-2 {
  border-color: rgba(56, 57, 71, 0.02) !important;
}

.grey2 {
  background-color: #AAAAAA !important;
  border-color: #AAAAAA !important;
}

.grey2--text {
  color: #AAAAAA !important;
  caret-color: #AAAAAA !important;
}

.border-grey2 {
  border-color: #AAAAAA !important;
}

.yellow {
  background-color: #EBB52F !important;
  border-color: #EBB52F !important;
}

.yellow--text {
  color: #EBB52F !important;
  caret-color: #EBB52F !important;
}

.border-yellow {
  border-color: #EBB52F !important;
}

.white {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.white--text {
  color: #ffffff !important;
  caret-color: #ffffff !important;
}

.border-white {
  border-color: #ffffff !important;
}

.white.lighten-70 {
  background-color: rgba(255, 255, 255, 0.7) !important;
  border-color: rgba(255, 255, 255, 0.7) !important;
}

.white--text.text--lighten-70 {
  color: rgba(255, 255, 255, 0.7) !important;
  caret-color: rgba(255, 255, 255, 0.7) !important;
}

.border-white-lighten-70 {
  border-color: rgba(255, 255, 255, 0.7) !important;
}

.white.lighten-50 {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.white--text.text--lighten-50 {
  color: rgba(255, 255, 255, 0.5) !important;
  caret-color: rgba(255, 255, 255, 0.5) !important;
}

.border-white-lighten-50 {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.white.lighten-40 {
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.white--text.text--lighten-40 {
  color: rgba(255, 255, 255, 0.4) !important;
  caret-color: rgba(255, 255, 255, 0.4) !important;
}

.border-white-lighten-40 {
  border-color: rgba(255, 255, 255, 0.4) !important;
}

.white.lighten-30 {
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.white--text.text--lighten-30 {
  color: rgba(255, 255, 255, 0.3) !important;
  caret-color: rgba(255, 255, 255, 0.3) !important;
}

.border-white-lighten-30 {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.blue1 {
  background-color: #3150A5 !important;
  border-color: #3150A5 !important;
}

.blue1--text {
  color: #3150A5 !important;
  caret-color: #3150A5 !important;
}

.border-blue1 {
  border-color: #3150A5 !important;
}

.blue1.lighten-15 {
  background-color: rgba(49, 80, 165, 0.15) !important;
  border-color: rgba(49, 80, 165, 0.15) !important;
}

.blue1--text.text--lighten-15 {
  color: rgba(49, 80, 165, 0.15) !important;
  caret-color: rgba(49, 80, 165, 0.15) !important;
}

.border-blue1-lighten-15 {
  border-color: rgba(49, 80, 165, 0.15) !important;
}

.blue1.lighten-7 {
  background-color: rgba(49, 80, 165, 0.07) !important;
  border-color: rgba(49, 80, 165, 0.07) !important;
}

.blue1--text.text--lighten-7 {
  color: rgba(49, 80, 165, 0.07) !important;
  caret-color: rgba(49, 80, 165, 0.07) !important;
}

.border-blue1-lighten-7 {
  border-color: rgba(49, 80, 165, 0.07) !important;
}

.blue1.lighten-3 {
  background-color: rgba(49, 80, 165, 0.03) !important;
  border-color: rgba(49, 80, 165, 0.03) !important;
}

.blue1--text.text--lighten-3 {
  color: rgba(49, 80, 165, 0.03) !important;
  caret-color: rgba(49, 80, 165, 0.03) !important;
}

.border-blue1-lighten-3 {
  border-color: rgba(49, 80, 165, 0.03) !important;
}

.blue2 {
  background-color: #7476A6 !important;
  border-color: #7476A6 !important;
}

.blue2--text {
  color: #7476A6 !important;
  caret-color: #7476A6 !important;
}

.border-blue2 {
  border-color: #7476A6 !important;
}

.blue2.lighten-80 {
  background-color: rgba(116, 118, 166, 0.8) !important;
  border-color: rgba(116, 118, 166, 0.8) !important;
}

.blue2--text.text--lighten-80 {
  color: rgba(116, 118, 166, 0.8) !important;
  caret-color: rgba(116, 118, 166, 0.8) !important;
}

.border-blue2-lighten-80 {
  border-color: rgba(116, 118, 166, 0.8) !important;
}

.blue2.lighten-60 {
  background-color: rgba(116, 118, 166, 0.6) !important;
  border-color: rgba(116, 118, 166, 0.6) !important;
}

.blue2--text.text--lighten-60 {
  color: rgba(116, 118, 166, 0.6) !important;
  caret-color: rgba(116, 118, 166, 0.6) !important;
}

.border-blue2-lighten-60 {
  border-color: rgba(116, 118, 166, 0.6) !important;
}

.blue2.lighten-30 {
  background-color: rgba(116, 118, 166, 0.3) !important;
  border-color: rgba(116, 118, 166, 0.3) !important;
}

.blue2--text.text--lighten-30 {
  color: rgba(116, 118, 166, 0.3) !important;
  caret-color: rgba(116, 118, 166, 0.3) !important;
}

.border-blue2-lighten-30 {
  border-color: rgba(116, 118, 166, 0.3) !important;
}

.blue2.lighten-20 {
  background-color: rgba(116, 118, 166, 0.2) !important;
  border-color: rgba(116, 118, 166, 0.2) !important;
}

.blue2--text.text--lighten-20 {
  color: rgba(116, 118, 166, 0.2) !important;
  caret-color: rgba(116, 118, 166, 0.2) !important;
}

.border-blue2-lighten-20 {
  border-color: rgba(116, 118, 166, 0.2) !important;
}

.blue2.lighten-15 {
  background-color: rgba(116, 118, 166, 0.15) !important;
  border-color: rgba(116, 118, 166, 0.15) !important;
}

.blue2--text.text--lighten-15 {
  color: rgba(116, 118, 166, 0.15) !important;
  caret-color: rgba(116, 118, 166, 0.15) !important;
}

.border-blue2-lighten-15 {
  border-color: rgba(116, 118, 166, 0.15) !important;
}

.blue3 {
  background-color: #3A66FF !important;
  border-color: #3A66FF !important;
}

.blue3--text {
  color: #3A66FF !important;
  caret-color: #3A66FF !important;
}

.border-blue3 {
  border-color: #3A66FF !important;
}

.bg.main {
  background-color: #ECEFF4 !important;
  border-color: #ECEFF4 !important;
}

.bg--text.text--main {
  color: #ECEFF4 !important;
  caret-color: #ECEFF4 !important;
}

.border-bg-main {
  border-color: #ECEFF4 !important;
}

.bg.sub {
  background-color: #F5F7FA !important;
  border-color: #F5F7FA !important;
}

.bg--text.text--sub {
  color: #F5F7FA !important;
  caret-color: #F5F7FA !important;
}

.border-bg-sub {
  border-color: #F5F7FA !important;
}

.bg.parts-blue {
  background-color: #F2F8FF !important;
  border-color: #F2F8FF !important;
}

.bg--text.text--parts-blue {
  color: #F2F8FF !important;
  caret-color: #F2F8FF !important;
}

.border-bg-parts-blue {
  border-color: #F2F8FF !important;
}

.link {
  background-color: #297AD8 !important;
  border-color: #297AD8 !important;
}

.link--text {
  color: #297AD8 !important;
  caret-color: #297AD8 !important;
}

.border-link {
  border-color: #297AD8 !important;
}

.link.over {
  background-color: #1C65BA !important;
  border-color: #1C65BA !important;
}

.link--text.text--over {
  color: #1C65BA !important;
  caret-color: #1C65BA !important;
}

.border-link-over {
  border-color: #1C65BA !important;
}

.link.extra-light {
  background-color: #80D1FF !important;
  border-color: #80D1FF !important;
}

.link--text.text--extra-light {
  color: #80D1FF !important;
  caret-color: #80D1FF !important;
}

.border-link-extra-light {
  border-color: #80D1FF !important;
}

.link.lighten-5 {
  background-color: rgba(41, 122, 216, 0.05) !important;
  border-color: rgba(41, 122, 216, 0.05) !important;
}

.link--text.text--lighten-5 {
  color: rgba(41, 122, 216, 0.05) !important;
  caret-color: rgba(41, 122, 216, 0.05) !important;
}

.border-link-lighten-5 {
  border-color: rgba(41, 122, 216, 0.05) !important;
}

.link.lighten-70 {
  background-color: rgba(41, 122, 216, 0.7) !important;
  border-color: rgba(41, 122, 216, 0.7) !important;
}

.link--text.text--lighten-70 {
  color: rgba(41, 122, 216, 0.7) !important;
  caret-color: rgba(41, 122, 216, 0.7) !important;
}

.border-link-lighten-70 {
  border-color: rgba(41, 122, 216, 0.7) !important;
}

.kgi {
  background-color: #B040A5 !important;
  border-color: #B040A5 !important;
}

.kgi--text {
  color: #B040A5 !important;
  caret-color: #B040A5 !important;
}

.border-kgi {
  border-color: #B040A5 !important;
}

.kpi {
  background-color: #F59331 !important;
  border-color: #F59331 !important;
}

.kpi--text {
  color: #F59331 !important;
  caret-color: #F59331 !important;
}

.border-kpi {
  border-color: #F59331 !important;
}

.border-blue1.bg.sub {
  border-color: #3150A5 !important;
}

.border-lightgray.blue1.lighten-3 {
  border-color: #cbcfe1 !important;
}

/* ==========
タイポグラフィ
========== */
.font-Light {
  font-weight: 300 !important;
  font-style: normal;
}

.font-Regular {
  font-weight: 400 !important;
  font-style: normal;
}

.font-Medium {
  font-weight: 500 !important;
  font-style: normal;
}

.font-Regular-en {
  font-weight: 400 !important;
  font-style: normal;
}

.font-Medium-en {
  font-weight: 500 !important;
  font-style: normal;
}

.font-SemiBold-en {
  font-weight: 600 !important;
  font-style: normal;
}

.font-Bold-en {
  font-weight: 700 !important;
  font-style: normal;
}

.text-display-1 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 64px;
  line-height: 1.2;
  font-weight: 300;
}

.text-display-2 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 48px;
  line-height: 1.2;
  font-weight: 300;
}

.text-h1 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 36px;
  line-height: 1.3;
  font-weight: 400;
}

.text-h2 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 28px;
  line-height: 1.3;
  font-weight: 400;
}

.text-h3 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 24px;
  line-height: 1.4;
  font-weight: 400;
}

.text-h4 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 400;
}

.text-h4-em {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
}

.text-h4-ls30 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.3em;
  font-weight: 400;
}

.text-h5 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
}

.text-h6 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 500;
}

.text-h7 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.text-body {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 18px;
  line-height: 1.8;
  font-weight: 400;
}

.text-body-1-medium {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 20px;
  line-height: 1.8;
  font-style: normal;
  font-weight: 500;
}

.text-body-2 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 18px;
  line-height: 1.8;
  font-style: normal;
  font-weight: 400;
}

.text-body-2-medium {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 18px;
  line-height: 1.8;
  font-style: normal;
  font-weight: 500;
}

.text-body-2-bold {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 18px;
  line-height: 1.8;
  font-style: normal;
  font-weight: 700;
}

.text-caption-1 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
}

.text-caption-2 {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 400;
}

.text-list {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 400;
}

.text-list-em {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 500;
}

.text-list li:nth-child(n+2),
.text-list-em li:nth-child(n+2) {
  margin-top: 4px;
}

.text-pulldown {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}

.text-extra-small {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
}

.text-display-1-en {
  font-family: fira-sans, sans-serif;
  font-size: 80px;
  line-height: 1;
  font-weight: 500;
}

.text-display-2-en {
  font-family: fira-sans, sans-serif;
  font-size: 64px;
  line-height: 1.1;
  font-weight: 500;
}

.text-display-3-en {
  font-family: fira-sans, sans-serif;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 500;
}

.text-h1-en {
  font-family: fira-sans, sans-serif;
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
}

.text-h2-en {
  font-family: fira-sans, sans-serif;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 600;
}

.text-h3-en {
  font-family: fira-sans, sans-serif;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
}

.text-h4-en {
  font-family: fira-sans, sans-serif;
  font-size: 20px;
  line-height: 1.3;
  font-weight: 600;
}

.text-h5-en {
  font-family: fira-sans, sans-serif;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
}

.text-h6-en {
  font-family: fira-sans, sans-serif;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 600;
}

.text-body-en {
  font-family: fira-sans, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.text-caption-1-en {
  font-family: fira-sans, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}

.text-caption-2-en {
  font-family: fira-sans, sans-serif;
  font-size: 12px;
  line-height: 1.6;
  font-weight: 400;
}

.text-catch-en {
  font-family: fira-sans, sans-serif;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 500;
}

.icon-32px {
  font-size: 32px;
}

.icon-40px {
  font-size: 40px;
}

/* ==========
レイアウト
========== */
/*doc
---
title: base
name: base
category: basics
---

ベースです。

*/
body {
  color: #383947;
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 1.125rem;
  line-height: 1.8;
  font-weight: 400;
  margin: 0;
  word-wrap: break-word;
  background: url(../img/bg.png) repeat-y center top #ECEFF4;
  background-size: 1633px 5px;
}

.defs {
  display: none;
}

p {
  margin: 0;
}

a {
  color: #F4392C;
  text-decoration: none;
  transition: color 150ms ease;
}
a.text-link {
  color: #297AD8;
  transition: opacity 150ms ease;
}
a.text-link:active, a.text-link:focus, a.text-link:hover {
  opacity: 0.75;
}

.text-link {
  color: #297AD8;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

svg {
  vertical-align: top;
  margin: 0;
}

.svg-icon {
  position: absolute;
  width: 0;
  height: 0;
}

input[type=text],
textarea {
  border: 1px solid rgba(116, 118, 166, 0.6);
}
input[type=text]:placeholder-shown,
textarea:placeholder-shown {
  border: 1px solid rgba(116, 118, 166, 0.3);
}

.theme-text {
  color: #F4392C;
}
html.multiple .theme-text {
  color: #009E77;
}

/*doc
---
title: header
name: header
category: basics
---

ヘッダ

*/
.header {
  transition: box-shadow 150ms ease;
  min-width: 1024px;
}
.header::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(116, 118, 166, 0.15);
}
html.single .header::after, html.multiple .header::after {
  bottom: -4px;
  height: 4px;
}
html.single .header::after {
  background-color: #F7554A;
}
html.multiple .header::after {
  background-color: #009E77;
}
.header.has-box-shadow {
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.1);
}
.header__btn-menu {
  font-size: 10px;
  line-height: 1;
  border-radius: 0;
  transition: background-color 150ms ease;
  position: relative;
  z-index: 1;
}
.header__btn-menu:focus {
  outline: none;
}
.header__btn-menu .hamburger-box {
  vertical-align: top;
  transition: all 150ms ease;
}
.header__btn-menu:hover .hamburger-box {
  opacity: 0.75;
}
.header__btn-menu.is-active {
  background-color: #F4392C;
}
.header__btn-menu.is-active .hamburger-inner,
.header__btn-menu.is-active .hamburger-inner::before,
.header__btn-menu.is-active .hamburger-inner::after {
  background-color: #fff;
}
html.single .header__btn-menu.is-active::after, html.multiple .header__btn-menu.is-active::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
}
.header__logo {
  position: relative;
}
.header__logo::before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 32px;
  left: 0;
  background-color: rgba(116, 118, 166, 0.3);
}
.header__target {
  background-color: #F5F7FA;
  border: 1px solid rgba(116, 118, 166, 0.15);
  border-radius: 100px;
  padding: 3px;
  margin: 0 48px 0 auto;
  display: flex;
}
.header__target-item {
  width: 268px;
  height: 46px;
  border-radius: 100px;
  transition: background-color 150ms ease;
}
.header__target-item:hover {
  background-color: #ffffff;
}
.header__target-item a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__target-item + .header__target-item {
  margin-left: 8px;
}
html:not(.multiple) .header__target-item.is-single {
  background-color: #F7554A;
  pointer-events: none;
}
html.multiple .header__target-item.is-multiple {
  background-color: #009E77;
  pointer-events: none;
}
.header__target-img {
  display: none;
}
html:not(.multiple) .header__target-img.is-single, html.multiple .header__target-img.is-multiple {
  display: block;
}
.header__target-title {
  font-size: 16px;
  margin-left: 12px;
}
html:not(.multiple) .header__target-item.is-single .header__target-title, html.multiple .header__target-item.is-multiple .header__target-title {
  color: #ffffff;
}
.header__target + .header__user {
  margin-left: 0;
}
.header__user-avatar {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__user-name, .header__user-icon {
  transition: opacity 150ms ease;
}
.header__user-icon {
  width: 24px;
  height: 24px;
  background: url(../img/icon/icon-circle-arrow-down.svg) no-repeat 0 0;
  background-size: 24px 24px;
}
.header__user-btn:hover .header__user-name,
.header__user-btn:hover .header__user-icon {
  opacity: 0.75;
}
.header__user-btn.is-active .header__user-icon {
  background-image: url(../img/icon/icon-circle-arrow-down_ov.svg);
}
.header__user-btn.is-active + .header__user-menu {
  display: block;
}
.header__user-menu {
  display: none;
}
.header__user-menu::before {
  width: 16px;
  height: 16px;
  content: "";
  background: url(../img/img_header__user-menu.svg) no-repeat 0 0;
  background-size: 16px 16px;
  position: absolute;
  top: -8px;
  right: 20px;
}
.header__user-menu-item:not(:first-child) {
  border-top: 1px solid rgba(116, 118, 166, 0.3);
}
.header__user-menu-link {
  transition: opacity 150ms ease;
}
.header__user-menu-link:hover {
  opacity: 0.75;
}

/*doc
---
title: subheader
name: subheader
category: basics
---

サブヘッダ

*/
.subheader {
  width: 100%;
  height: 72px;
  background-color: #F2FAF0;
  border-bottom: 1px solid rgba(116, 118, 166, 0.3);
  padding: 0 40px;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 104px;
}
html.multiple .subheader.is-active {
  display: flex;
}
html.multiple:has(.kgi-summary) .subheader {
  display: none;
}
.subheader__title {
  flex-shrink: 0;
  font-size: 16px;
  color: #009E77;
}
.subheader__target {
  font-size: 14px;
  width: 100%;
  height: 40px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  position: relative;
  transition: border-color 150ms ease;
  max-width: 1069px;
}
.subheader__target-title {
  color: #297AD8;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 158, 119, 0.2);
  border-radius: 12px;
  padding: 0 16px;
  line-height: 36px;
  display: flex;
  justify-content: center;
}
.subheader__target-title:hover {
  border-color: rgba(0, 158, 119, 0.5);
}
.subheader__target-icon {
  font-size: 24px;
  color: #009E77;
  transform: translateY(-2px);
  line-height: 1;
  margin-left: 8px;
  margin-right: 8px;
}
.subheader__target-content {
  transition: color 150ms ease;
  flex-grow: 1;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 158, 119, 0.2);
  border-radius: 12px;
  padding: 0 16px;
  line-height: 36px;
}
.subheader__target-content:hover {
  border-color: rgba(0, 158, 119, 0.5);
}
.subheader__target-content:hover {
  color: rgba(56, 57, 71, 0.7);
}
.subheader__target-detail {
  font-size: 14px;
  color: #3150A5;
  line-height: 1.8;
  width: calc(100% - 48px);
  background-color: #ffffff;
  border: 1px solid rgba(56, 57, 71, 0.45);
  border-radius: 6px;
  padding: 16px;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 150ms ease;
}
.subheader__target:hover .subheader__target-detail {
  opacity: 1;
}
.subheader__target-detail::before {
  content: "";
  width: 10px;
  height: 9px;
  background: url(../img/img_subheader__target-detail.svg) no-repeat 0 0;
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
}
.subheader__button {
  flex-shrink: 0;
  font-size: 14px;
  color: #ffffff;
  width: 144px;
  height: 40px;
  background-color: #297AD8;
  border-radius: 8px;
  margin-left: 24px;
  transition: background-color 150ms ease;
}
.subheader__button:hover {
  background-color: #1C65BA;
}
.subheader__btn-save-container {
  padding-left: 24px;
  margin-left: 24px;
  border-left: 1px solid rgba(116, 118, 166, 0.3);
}
.subheader__btn-save {
  padding: 4px;
  font-size: 32px;
  line-height: 1;
  color: rgba(56, 57, 71, 0.7);
  transition: opacity 150ms ease;
}
.subheader__btn-save:hover {
  color: #383947;
}
.subheader__btn-save:disabled {
  color: rgba(116, 118, 166, 0.3);
  cursor: not-allowed;
}

/*doc
---
title: nav
name: nav
category: basics
---

ナビゲーションのスタイルです。

*/
.nav.is-active .nav__bg {
  display: block;
}
.nav.is-active .nav__container {
  display: block;
}
.nav__bg {
  display: none;
}
.nav__container {
  display: none;
  width: 414px;
  top: 104px;
  height: calc(100vh - 104px);
  overflow-y: scroll;
  overscroll-behavior-y: contain;
}
html.single .nav__container, html.multiple .nav__container {
  width: 416px;
  top: 100px;
  height: calc(100vh - 100px);
  background-color: #ECEFF4;
}
html.multiple .nav__dropdown.is-single {
  display: none;
}
.nav__dropdown.is-multiple {
  display: none;
}
html.multiple .nav__dropdown.is-multiple {
  display: block;
}
.nav__dropdown-button {
  width: 100%;
  height: 76px;
  background-color: #F7554A;
  display: flex;
  align-items: center;
  position: relative;
}
.nav__dropdown.is-multiple .nav__dropdown-button {
  background-color: #009E77;
}
.nav__dropdown-button::after {
  content: "";
  width: 24px;
  height: 24px;
  background: url(../img/icon/icon-plus.svg) no-repeat 0 0;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  transition: background-image 150ms ease;
}
.nav__dropdown.is-open .nav__dropdown-button::after {
  background-image: url(../img/icon/icon-minus.svg);
}
.nav__dropdown.is-single .nav__dropdown-button:hover::after {
  background-image: url(../img/icon/icon-plus-orange.svg);
}
.nav__dropdown.is-single.is-open .nav__dropdown-button:hover::after {
  background-image: url(../img/icon/icon-minus-orange.svg);
}
.nav__dropdown.is-multiple .nav__dropdown-button:hover::after {
  background-image: url(../img/icon/icon-plus-green.svg);
}
.nav__dropdown.is-multiple.is-open .nav__dropdown-button:hover::after {
  background-image: url(../img/icon/icon-minus-green.svg);
}
.nav__dropdown-icon {
  padding: 0 28px 0 44px;
  position: relative;
}
.nav__dropdown-icon::after {
  content: "";
  width: 1px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translateY(-50%);
}
.nav__dropdown-title {
  font-size: 18px;
  color: #ffffff;
  margin-left: 25px;
}
.nav__dropdown-content-wrapper {
  overflow: hidden;
  max-height: 0;
  transition: max-height 150ms ease;
}
.nav__dropdown.is-open .nav__dropdown-content-wrapper {
  max-height: var(--height);
}
.nav__dropdown-content {
  background-color: #ffffff;
  border-left: 8px solid #F7554A;
}
.nav__dropdown.is-multiple .nav__dropdown-content {
  border-left-color: #009E77;
}
.nav__category-item {
  border-bottom: 1px solid rgba(116, 118, 166, 0.15);
  transition: background-color 150ms ease;
}
html.single .nav__category-item:hover, html.multiple .nav__category-item:hover {
  background-color: #F5F7FA;
}
html.single .nav__category-item:hover:has(.is-diabled), html.multiple .nav__category-item:hover:has(.is-diabled) {
  background-color: transparent;
}
.nav__category-link.is-diabled {
  pointer-events: none;
}
.nav__category-link.is-diabled .nav__category-img img:first-child {
  opacity: 0.15 !important;
}
.nav__category-link.is-diabled .nav__category-number {
  color: rgba(116, 118, 166, 0.3) !important;
}
.nav__category-link.is-diabled .nav__category-name {
  color: rgba(116, 118, 166, 0.3) !important;
}
.nav__category-link:hover .nav__category-img img:last-child {
  opacity: 1;
}
.nav__category-link:hover .nav__category-number {
  color: #F4392C;
}
.nav__category-link:hover .nav__category-name {
  color: #383947;
}
.nav__category-img {
  position: relative;
}
.nav__category-img img:last-child {
  transition: opacity 150ms ease;
  opacity: 0;
  position: absolute;
  top: 0;
}
.nav__category-number {
  transition: color 150ms ease;
  color: rgba(255, 77, 91, 0.7);
}
.nav__dropdown.is-multiple .nav__category-number {
  color: rgba(0, 158, 119, 0.7);
}
.nav__dropdown.is-multiple .nav__category-item:hover .nav__category-number {
  color: #009E77;
}
.nav__category-name {
  transition: color 150ms ease;
  color: rgba(56, 57, 71, 0.7);
}
.nav__category-name::before {
  content: "";
  display: block;
  width: 8px;
  height: 1px;
  background-color: currentColor;
  position: absolute;
  left: -18px;
  top: calc(50% + 1px);
}
.nav__guideline {
  border-bottom: 1px solid rgba(116, 118, 166, 0.15);
}
.nav__dropdown.is-single .nav__guideline-heading {
  color: #F7554A;
}
.nav__dropdown.is-multiple .nav__guideline-heading {
  color: #009E77;
}
.nav__guideline-link {
  color: rgba(56, 57, 71, 0.45);
}
.nav__guideline-link:hover {
  color: #383947;
}
.nav__guideline-link.is-disabled {
  pointer-events: none;
}
.nav__guideline-icon {
  line-height: 1;
}
.nav__guideline-link.is-disabled .nav__guideline-icon .icon-arrow::before {
  color: rgba(116, 118, 166, 0.3);
}
.nav__guideline-link.is-disabled .nav__guideline-name {
  color: rgba(116, 118, 166, 0.3);
}
.nav__dashboard {
  border-bottom: 1px solid rgba(116, 118, 166, 0.15);
}
.nav__dashboard:last-child {
  border-bottom: none;
}
.nav__dashboard-link {
  color: rgba(56, 57, 71, 0.45);
}
.nav__dashboard-link:hover {
  color: #383947;
}
.nav__dashboard-icon {
  font-size: 32px;
  line-height: 1;
}
.nav__function {
  border-bottom: 1px solid rgba(116, 118, 166, 0.15);
}
.nav__function:last-child {
  border-bottom: none;
}
.nav__function-item.has-new .nav__function-icon {
  position: relative;
}
.nav__function-item.has-new .nav__function-icon::before {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  right: 3px;
  width: 8px;
  height: 8px;
  background-color: #F4392C;
  border-radius: 100%;
}
.nav__function-link {
  color: rgba(56, 57, 71, 0.45);
}
.nav__function-link:hover {
  color: #383947;
}
.nav__function-link.is-multiple {
  display: none;
}
html.multiple .nav__function-link.is-multiple {
  display: flex;
}
.nav__function-link.is-single {
  display: none;
}
html.single .nav__function-link.is-single {
  display: flex;
}
.nav__function-icon {
  font-size: 32px;
  line-height: 1;
}

/*doc
---
title: footer
name: footer
category: basics
---

フッタ

*/
.footer {
  grid-row: 2/3;
}
.footer__nav-item {
  position: relative;
}
.footer__nav-item:not(:first-child) {
  padding-left: 1px;
}
.footer__nav-item:not(:first-child)::before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  top: 4.5px;
  bottom: 4.5px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
}
.footer__nav-link {
  transition: opacity 150ms ease;
}
.footer__nav-link:hover {
  opacity: 0.75;
}
.footer__copyright small {
  font-size: 100%;
}

/*doc
---
title: wrapper
name: wrapper
category: basics
---

ページ全体です。

*/
#wrapper {
  padding-top: constant(safe-area-inset-top);
  padding-right: constant(safe-area-inset-right);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
}

#wrapper {
  min-width: 1024px;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}

/*doc
---
title: contents
name: contents
category: basics
---

コンテンツ領域です。

*/
.page-container {
  grid-row: 1;
  padding-top: 104px;
  padding-bottom: 124px;
  transition: padding-top 150ms ease;
}
html.multiple .page-container {
  padding-top: 176px;
}
html.multiple:has(.kgi-summary) .page-container {
  padding-top: 104px;
}

.centered-container {
  max-width: 1600px;
  margin: 0 auto;
}

.centered-container-1456 {
  max-width: 1456px;
  margin: 0 auto;
}

.centered-container-1056 {
  max-width: 1056px;
  margin: 0 auto;
}

.centered-container-784 {
  max-width: 784px;
  margin: 0 auto;
}

.page-content {
  padding-left: 64px;
  padding-right: 64px;
}

/*doc
---
title: nav-directory
name: nav-directory
category: basics
---

パンくずナビです。

*/
.nav-directory {
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.1);
}
.nav-directory__item {
  position: relative;
}
.nav-directory__item:not(:first-child) {
  margin-left: 42px;
}
.nav-directory__item:not(:first-child)::before {
  content: "";
  display: inline-block;
  width: 11px;
  height: 19px;
  position: absolute;
  left: -25px;
  top: 4px;
  background: url(../img/img_nav-directory.svg) no-repeat 0 0;
  background-size: 11px 19px;
}

/*doc
---
title: box-toast
name: box-toast
category: basics
---

トースト表示です。

*/
.box-toast {
  top: 104px;
  transform: translate(-50%, -100%);
  transition: transform 0.15s ease;
}
.box-toast.is-active {
  transform: translate(-50%, 0%);
}
.box-toast__text {
  transform: translateY(-1px);
}

.box-bordered {
  background-color: #ECEFF4;
  border-top: 1px solid rgba(116, 118, 166, 0.2);
  border-bottom: 1px solid rgba(116, 118, 166, 0.2);
}

.box-xy-bordered {
  background-color: #fff;
  border: 1px solid rgba(116, 118, 166, 0.2);
}
.box-xy-bordered.is-green {
  background-color: rgba(0, 158, 119, 0.05);
  border: 1px solid rgba(0, 158, 119, 0.3);
}
.box-xy-bordered.is-grey {
  background-color: rgba(56, 57, 71, 0.02);
  border: 1px solid rgba(56, 57, 71, 0.1);
}

.icon-bookmark-on {
  font-size: 32px;
  color: #F4392C;
}

.icon-bookmark-off {
  font-size: 32px;
  color: rgba(56, 57, 71, 0.45);
}

/*doc
---
title: width
name: width
category: basics
---

幅指定です。

*/
.w-78 {
  width: 19.5rem;
}

/*doc
---
title: left
name: left
category: basics
---

左からの値です。

*/
.left-78 {
  left: 19.5rem;
}

.btn {
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color 150ms ease;
}
.btn:hover {
  background-color: rgba(56, 57, 71, 0.7) !important;
}
.btn.red:hover {
  background-color: rgba(255, 77, 91, 0.7) !important;
}
.btn.red:disabled, .btn.red.is-disabled {
  background-color: rgba(56, 57, 71, 0.45) !important;
  cursor: not-allowed;
}
.btn.link:hover {
  background-color: rgba(41, 122, 216, 0.7) !important;
}
.btn.link:disabled, .btn.link.is-disabled {
  background-color: rgba(56, 57, 71, 0.45) !important;
  cursor: not-allowed;
}
.btn:disabled, .btn.is-disabled {
  background-color: rgba(56, 57, 71, 0.45) !important;
  cursor: not-allowed;
}

.btn-bookmark {
  border: 1px solid rgba(116, 118, 166, 0.3);
  height: 46px;
  width: 46px;
  transition: all 150ms ease;
}
.btn-bookmark .icon-bookmark-off {
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
}
.btn-bookmark .icon-bookmark-on {
  grid-column: 1;
  grid-row: 1;
  opacity: 0;
  color: #fff;
  z-index: 0;
}
.btn-bookmark:hover {
  border-color: rgba(116, 118, 166, 0.6);
  background-color: rgba(49, 80, 165, 0.03);
}
.btn-bookmark:hover .icon-bookmark-off {
  color: #383947;
}
.btn-bookmark:hover .icon-bookmark-on {
  opacity: 1;
}
.btn-bookmark.is-active .icon-bookmark-off {
  display: none;
}
.btn-bookmark.is-active .icon-bookmark-on {
  opacity: 1;
  color: #F4392C;
}

.btn-outline {
  color: rgba(56, 57, 71, 0.7);
  border: 2px solid currentColor;
  background-color: #fff !important;
  transition: color 150ms ease;
}
.btn-outline:hover {
  color: #383947;
  background-color: #fff !important;
}

.btn-icon {
  color: #297AD8;
  transition: color 150ms ease;
  vertical-align: middle;
  line-height: 1;
}
.btn-icon:hover {
  color: rgba(41, 122, 216, 0.7);
}
.btn-icon:disabled, .btn-icon.is-disabled {
  color: rgba(56, 57, 71, 0.45);
  cursor: not-allowed;
}

.btn-icon-delete {
  color: rgba(0, 0, 0, 0.54);
  vertical-align: middle;
  line-height: 1;
  border-radius: 100px;
  background-color: transparent;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-icon-delete:not(:disabled):hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.btn-icon-delete:disabled, .btn-icon-delete.is-disabled {
  color: rgba(56, 57, 71, 0.45);
}

.btn-link {
  color: #fff;
  background-color: #297AD8;
}
.btn-link:hover {
  border-color: #1C65BA;
  background-color: #1C65BA;
}
.btn-link:disabled {
  border-color: rgba(116, 118, 166, 0.3);
  background-color: rgba(116, 118, 166, 0.3);
  cursor: not-allowed;
}

.ui-checkbox {
  position: relative;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
}
.ui-checkbox__input {
  position: absolute;
  opacity: 0;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
}
.ui-checkbox__input:checked + .ui-checkbox__icon::before {
  border: none;
  background-color: rgba(255, 77, 91, 0.9);
}
.modal-window--analysis-setting .ui-checkbox__input:checked + .ui-checkbox__icon::before {
  background-color: #009E77;
}
.ui-checkbox__input:checked + .ui-checkbox__icon::after {
  content: "\e912";
}
.ui-checkbox__input:indeterminate + .ui-checkbox__icon::before, .ui-checkbox__input.indeterminate + .ui-checkbox__icon::before {
  border: none;
  background-color: rgba(255, 77, 91, 0.9);
}
.modal-window--analysis-setting .ui-checkbox__input:indeterminate + .ui-checkbox__icon::before, .modal-window--analysis-setting .ui-checkbox__input.indeterminate + .ui-checkbox__icon::before {
  background-color: #009E77;
}
.ui-checkbox__input:indeterminate + .ui-checkbox__icon::after, .ui-checkbox__input.indeterminate + .ui-checkbox__icon::after {
  content: "\e911";
}
.ui-checkbox__input:disabled + .ui-checkbox__icon::before {
  border-color: rgba(56, 57, 71, 0.35);
  background-color: rgba(116, 118, 166, 0.2);
}
.ui-checkbox__input:disabled:checked + .ui-checkbox__icon::before {
  background-color: rgba(56, 57, 71, 0.35);
}
.ui-checkbox__input:disabled:indeterminate + .ui-checkbox__icon::before, .ui-checkbox__input.indeterminate:disabled + .ui-checkbox__icon::before {
  background-color: rgba(56, 57, 71, 0.35);
}
.ui-checkbox__input:disabled ~ .ui-checkbox__ripple {
  display: none;
}
.ui-checkbox__input:disabled ~ .ui-checkbox__label {
  color: rgba(56, 57, 71, 0.35);
}
.ui-checkbox__input:checked:disabled ~ .ui-checkbox__label {
  color: #383947;
}
.ui-checkbox__icon {
  speak: never;
  font-feature-settings: "liga";
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1;
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-flex;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  text-indent: 0;
  vertical-align: middle;
  user-select: none;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 8px;
}
.ui-checkbox__icon::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  background-clip: content-box;
  border: 2px solid rgba(56, 57, 71, 0.45);
  background-color: #fff;
  z-index: 0;
  border-radius: 4px;
}
.ui-checkbox__icon::after {
  position: relative;
  content: "";
  color: #fff;
  z-index: 1;
}
.ui-checkbox__ripple {
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  left: -8px;
  transform: translateY(-50%);
  border-radius: 100%;
  z-index: 2;
}

.ui-radio {
  position: relative;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
}
.ui-radio__input {
  position: absolute;
  opacity: 0;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
}
.ui-radio__input:checked + .ui-radio__icon::before {
  border-color: rgba(255, 77, 91, 0.9);
}
.multiple .ui-radio__input:checked + .ui-radio__icon::before {
  border-color: #00B295;
}
.ui-radio__input:checked + .ui-radio__icon::after {
  background-color: rgba(255, 77, 91, 0.9);
}
.multiple .ui-radio__input:checked + .ui-radio__icon::after {
  background-color: #00B295;
}
.ui-radio__input:disabled + .ui-radio__icon::before {
  border-color: rgba(56, 57, 71, 0.35);
  background-color: rgba(116, 118, 166, 0.2);
}
.ui-radio__input:disabled + .ui-radio__icon::after {
  background-color: transparent;
}
.ui-radio__input:disabled:checked + .ui-radio__icon::before {
  border-color: rgba(56, 57, 71, 0.35);
  background-color: rgba(116, 118, 166, 0.2);
}
.ui-radio__input:disabled:checked + .ui-radio__icon::after {
  background-color: rgba(56, 57, 71, 0.35);
}
.ui-radio__input:disabled ~ .ui-radio__ripple {
  display: none;
}
.ui-radio__input:disabled ~ .ui-radio__label {
  color: rgba(56, 57, 71, 0.35);
}
.ui-radio__input:checked:disabled ~ .ui-radio__label {
  color: #383947;
}
.ui-radio__icon {
  speak: never;
  font-feature-settings: "liga";
  font-size: 16px;
  letter-spacing: normal;
  line-height: 1;
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  text-indent: 0;
  vertical-align: middle;
  user-select: none;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.ui-radio__icon::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  background-clip: content-box;
  border: 2px solid rgba(56, 57, 71, 0.45);
  background-color: #fff;
  z-index: 0;
  border-radius: 100%;
}
.ui-radio__icon::after {
  position: relative;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: transparent;
  z-index: 1;
}
.ui-radio__ripple {
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  border-radius: 100%;
  z-index: 2;
}

.ui-select {
  position: relative;
}
.ui-select__btn {
  cursor: pointer;
  min-width: 124px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid rgba(116, 118, 166, 0.3);
  transition: all 150ms ease;
  background-color: #fff;
  white-space: nowrap;
}
.ui-select__btn:hover {
  border-color: rgba(116, 118, 166, 0.6);
}
.ui-select__btn.is-active {
  border-color: #297AD8;
}
.ui-select__btn.is-active .ui-select__icon {
  transform: rotate(270deg);
}
.ui-select__btn.is-disabled {
  background-color: #E1E6ED;
  border-color: rgba(116, 118, 166, 0.15);
  color: rgba(56, 57, 71, 0.35);
  cursor: default;
}
.ui-select__btn.is-disabled .ui-select__icon {
  color: currentColor;
}
.ui-select__icon {
  transform: rotate(90deg);
  color: rgba(56, 57, 71, 0.7);
  transition: all 150ms ease;
}
.ui-select__list {
  position: absolute;
  top: 50px;
  z-index: 10;
  width: 100%;
  max-height: 300px;
  border: 1px solid rgba(116, 118, 166, 0.3);
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.15);
  overflow: hidden;
  overflow-y: auto;
}
.ui-select__item {
  transition: all 150ms ease;
  color: rgba(56, 57, 71, 0.7);
  padding: 11px 16px 14px;
}
.ui-select__item:hover {
  color: #297AD8;
  background-color: rgba(49, 80, 165, 0.07);
}

.form-table {
  border-top: 2px solid #c1c4d9;
}
.form-table tbody th, .form-table tbody td {
  border-bottom: 1px solid #cbcfe1;
  vertical-align: middle;
}
.form-table tbody th {
  white-space: nowrap;
  padding: 18px 16px;
  background-color: rgba(49, 80, 165, 0.15);
  color: #3150A5;
  text-align: left;
}
.form-table tbody td {
  background-color: rgba(49, 80, 165, 0.03);
  padding: 8px 16px;
}
.form-table__col-head {
  width: 17%;
}
.form-table__col-data {
  width: 83%;
}

.badge-shared {
  display: inline-block;
  background: #dedede;
  padding: 0.25em 0.5rem;
  font-size: 12px;
  border-radius: 6px;
  vertical-align: bottom;
  margin-left: 0.5rem;
}

.tooltip {
  position: relative;
}
.tooltip:hover .tooltip__box {
  opacity: 1;
}
.tooltip__box {
  opacity: 0;
  position: absolute;
  top: calc(1.8em + 8px);
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid rgba(56, 57, 71, 0.45);
  background-color: #fff;
  padding: 14.39px 16px 20px;
  color: #3150A5;
  text-align: left;
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.15);
  z-index: 2;
  border-radius: 8px;
  pointer-events: none;
  transition: opacity 150ms ease;
}
.tooltip__box::before {
  position: absolute;
  display: block;
  content: "";
  background: url(../img/bg_nav-graph__preview.png) no-repeat 0 0;
  background-size: contain;
  width: 11px;
  height: 10px;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.top-main-visual {
  margin-left: -64px;
  margin-right: -64px;
}
.top-main-visual img {
  height: 312px;
}

.top-page-header__btn-item:hover .top-page-header__description {
  opacity: 0.75;
}
.top-page-header__btn-icon {
  font-size: 32px;
  line-height: 1;
}
.top-page-header__description {
  transition: opacity 150ms ease;
}

.category-card {
  border-radius: 32px;
  box-shadow: inset 0px 0px 0px 1px rgba(116, 118, 166, 0.2);
}
.category-card:nth-child(n+2) {
  margin-top: 32px;
}

.category-header__img {
  width: 220px;
  margin-left: 14px;
  margin-right: 93px;
}
.category-header__text {
  border-left: 1px solid rgba(56, 57, 71, 0.7);
}
.category-header__text::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -2px;
  width: 3px;
  height: 113px;
  background-color: #F4392C;
}
html.multiple .category-header__text::before {
  background-color: #009E77;
}
html.multiple .category-header__subtitle-number {
  color: #009E77;
}
.category-header__subtitle-text {
  position: relative;
}
html.multiple .category-header__subtitle-text {
  color: #009E77;
}
.category-header__subtitle-text::before {
  content: "";
  display: block;
  width: 8px;
  height: 1px;
  background-color: currentColor;
  position: absolute;
  left: -18px;
  top: calc(50% + 1px);
}
.category-header__title {
  margin-top: 5px;
}
.category-header__title-link:hover .category-header__title-text {
  opacity: 0.75;
}
.category-header__title-link:hover .category-header__title-icon {
  color: #F4392C;
}
html.multiple .category-header__title-link:hover .category-header__title-icon {
  color: #009E77;
}
.category-header__title-link.is-disabled {
  pointer-events: none;
}
.category-header__title-link.is-disabled .category-header__title-icon {
  color: rgba(116, 118, 166, 0.3);
}
.category-header__title-text {
  transition: opacity 150ms ease;
}
.category-header__title-icon {
  font-size: 32px;
  line-height: 1;
  transform: translateY(2px);
  transition: color 150ms ease;
  position: relative;
}
.category-header__title-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 100%;
}
.category-header__title-icon .icon-arrow-circle {
  position: relative;
  z-index: 1;
}

.category-list__item {
  background-color: #ffffff;
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.1), inset 0px 0px 0px 1px rgba(116, 118, 166, 0.3);
}
.category-list__item:hover .category-list__title {
  opacity: 0.75;
}
.category-list__item:hover .category-list__icon {
  color: #F4392C;
}
html.multiple .category-list__item:hover .category-list__icon {
  color: #009E77;
}
.category-list__item.is-disabled {
  background-color: #F5F7FA;
  border: 1px solid rgba(116, 118, 166, 0.15);
  box-shadow: none;
  pointer-events: none;
}
.category-list__img {
  flex: 0 0 160px;
}
.category-list__item.is-disabled .category-list__img {
  opacity: 0.5;
}
.category-list__title {
  transition: opacity 150ms ease;
}
.category-list__item.is-disabled .category-list__title {
  color: rgba(56, 57, 71, 0.35);
}
.category-list__subtitle {
  color: #7476A6;
}
.category-list__item.is-disabled .category-list__subtitle {
  color: rgba(56, 57, 71, 0.35);
}
.category-list__icon {
  transition: color 150ms ease;
}
.category-list__item.is-disabled .category-list__icon .icon-arrow-circle-s::before {
  color: rgba(116, 118, 166, 0.15);
}

.news-list {
  max-width: 1327px;
  padding-left: 136px;
  padding-right: 136px;
  margin: 0 auto;
}
.news-list__list {
  background-color: #ECEFF4;
  border-top: 2px solid #383947;
  border-bottom: 2px solid #383947;
}
.news-list__item {
  position: relative;
}
.news-list__item:not(:first-child) {
  border-top: 1px solid rgba(116, 118, 166, 0.2);
}
.news-list__link {
  transition: background-color 150ms ease;
  color: #297AD8;
}
.news-list__link:hover {
  background-color: #F5F7FA;
}
.news-list__new {
  left: -117px;
}
.news-list__date {
  flex: 0 0 104px;
}

.form-container {
  max-width: 512px;
  margin: 0 auto;
}

.form-control textarea,
.form-control input[type=text],
.form-control input[type=password],
.form-control input[type=email],
.form-control input[type=tel],
.form-control input[type=url],
.form-control input[type=search] {
  box-shadow: 0 0 0 1px rgba(116, 118, 166, 0.3);
  outline: none;
  transition: box-shadow 150ms ease;
}
.form-control textarea:placeholder-shown,
.form-control input[type=text]:placeholder-shown,
.form-control input[type=password]:placeholder-shown,
.form-control input[type=email]:placeholder-shown,
.form-control input[type=tel]:placeholder-shown,
.form-control input[type=url]:placeholder-shown,
.form-control input[type=search]:placeholder-shown {
  color: rgba(56, 57, 71, 0.35);
}
.form-control textarea:focus,
.form-control input[type=text]:focus,
.form-control input[type=password]:focus,
.form-control input[type=email]:focus,
.form-control input[type=tel]:focus,
.form-control input[type=url]:focus,
.form-control input[type=search]:focus {
  box-shadow: 0 0 0 1px rgba(116, 118, 166, 0.6);
}
.form-control textarea:disabled,
.form-control input[type=text]:disabled,
.form-control input[type=password]:disabled,
.form-control input[type=email]:disabled,
.form-control input[type=tel]:disabled,
.form-control input[type=url]:disabled,
.form-control input[type=search]:disabled {
  background-color: #E1E6ED;
}
.form-control textarea.is-error,
.form-control input[type=text].is-error,
.form-control input[type=password].is-error,
.form-control input[type=email].is-error,
.form-control input[type=tel].is-error,
.form-control input[type=url].is-error,
.form-control input[type=search].is-error {
  color: #F4392C;
  box-shadow: 0 0 0 1px #F4392C;
}

.category-page-header__img {
  width: 80px;
}
.category-page-header__text {
  border-left: 1px solid rgba(56, 57, 71, 0.7);
}
.category-page-header__subtitle {
  min-height: 26px;
}
html.multiple .category-page-header__subtitle-number {
  color: #009E77;
}
.category-page-header__subtitle-text {
  position: relative;
}
html.multiple .category-page-header__subtitle-text {
  color: #009E77;
}
.category-page-header__subtitle-text::before {
  content: "";
  display: block;
  width: 8px;
  height: 1px;
  background-color: currentColor;
  position: absolute;
  left: -18px;
  top: calc(50% + 1px);
}
.category-page-header__subtitle-text--no-line::before {
  content: none;
}
.category-page-header__title {
  margin-top: 5px;
}
.category-page-header__title-link:hover .category-page-header__title-text {
  opacity: 0.75;
}
.category-page-header__title-link:hover .category-page-header__title-icon {
  color: #F4392C;
}
.category-page-header__title-text {
  transition: opacity 150ms ease;
}
.category-page-header__title-icon {
  font-size: 32px;
  line-height: 1;
  transform: translateY(2px);
  transition: color 150ms ease;
  position: relative;
}
.category-page-header__title-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 100%;
}
.category-page-header__title-icon .icon-arrow-circle {
  position: relative;
  z-index: 1;
}

.nav-category__item {
  position: relative;
}
.nav-category__item::after {
  content: "";
  display: block;
  position: absolute;
  top: 14px;
  bottom: 14px;
  right: 0;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}
.nav-category__item:last-child::after {
  content: none;
}
.nav-category__item.is-active .nav-category__btn {
  opacity: 1;
}
.nav-category__item.is-active .nav-category__btn::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #F4392C;
  border-radius: 6px 6px 0px 0px;
}
html.multiple .nav-category__item.is-active .nav-category__btn::before {
  background-color: #00BA8C;
}
.nav-category__item.is-disabled {
  pointer-events: none;
}
.nav-category__item.is-disabled .nav-category__btn {
  color: rgba(255, 255, 255, 0.3) !important;
}
.nav-category__btn {
  color: #ffffff;
  opacity: 0.75;
  transition: opacity 150ms ease;
  position: relative;
}
.nav-category__btn:hover {
  opacity: 1;
}

.box-graph {
  border: 1px solid rgba(116, 118, 166, 0.2) !important;
}
.box-graph__body {
  border-top: 1px solid rgba(116, 118, 166, 0.2);
}
.box-graph__middle + .box-graph__body {
  border-top: none;
}
.box-graph__middle {
  border-top: 1px solid rgba(116, 118, 166, 0.2);
}

.nav-graph {
  position: relative;
}
.nav-graph::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  background-color: #fff;
  z-index: 1;
}
.nav-graph.my-dashboard li {
  display: none;
}
.nav-graph.my-dashboard li.show {
  display: list-item;
}
.nav-graph.my-dashboard.show-all li {
  display: list-item;
}
.nav-graph__item {
  position: relative;
}
.nav-graph__item::before {
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  width: 1px;
  height: 20px;
  background-color: rgba(116, 118, 166, 0.2);
}
.nav-graph__item.is-active .nav-graph__btn {
  opacity: 1;
  color: #F4392C;
}
.nav-graph__item.is-active .nav-graph__btn::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 8px;
  height: 2px;
  background-color: #F4392C;
}
html.multiple .nav-graph__item.is-active .nav-graph__btn {
  color: #009E77;
}
html.multiple .nav-graph__item.is-active .nav-graph__btn::before {
  background-color: #009E77;
}
.nav-graph__item.is-disabled {
  pointer-events: none;
}
.nav-graph__item.is-disabled .nav-graph__btn {
  color: rgba(56, 57, 71, 0.35);
}
.nav-graph__btn {
  color: #297AD8;
  position: relative;
  display: inline-block;
}
.nav-graph__btn:hover .nav-graph__text {
  opacity: 0.75;
}
.nav-graph__btn:hover .nav-graph__preview {
  opacity: 1;
}
.nav-graph__text {
  transition: opacity 150ms ease;
}
.nav-graph__preview {
  opacity: 0;
  position: absolute;
  top: 46px;
  left: 50%;
  transform: translateX(-50%);
  width: 272px;
  border: 1px solid rgba(56, 57, 71, 0.45);
  background-color: #fff;
  padding: 9px 15px;
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.15);
  z-index: 2;
  border-radius: 8px;
  pointer-events: none;
  transition: opacity 150ms ease;
}
.nav-graph__preview::before {
  position: absolute;
  display: block;
  content: "";
  background: url(../img/bg_nav-graph__preview.png) no-repeat 0 0;
  background-size: contain;
  width: 11px;
  height: 10px;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

#embeddingContainer {
  min-height: 700px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.action-area {
  min-width: 0;
}
.action-area .action-area__tag {
  max-width: 512px;
  min-width: 0;
  width: 512px;
}
.action-area .tag-container {
  background-color: #ffffff;
  border: 1px solid rgba(116, 118, 166, 0.3);
}
.action-area .tag-container.is-active .tag-container__icon,
.action-area .tag-container.is-active .tag-container__text {
  color: #F4392C;
}
.action-area .tag-container__icon {
  color: rgba(56, 57, 71, 0.45);
}
.action-area .tag-container__icon .icon:before {
  font-size: 22px;
}
.action-area .tag-container__label {
  white-space: nowrap;
  border-left: 1px solid rgba(116, 118, 166, 0.2);
}
.action-area .tag-container__text {
  flex-grow: 1;
  min-width: 0;
  color: rgba(56, 57, 71, 0.35);
}
.action-area .tag-container__text span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.action-area .tag-container__text.is-active {
  color: #F4392C;
}
.action-area .btn-tag {
  white-space: nowrap;
  border-left: 1px solid rgba(116, 118, 166, 0.2);
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.action-area .btn-tag:hover {
  background-color: #F2F8FF;
}
.action-area .yearButton {
  cursor: pointer;
  min-width: 124px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid rgba(116, 118, 166, 0.3);
  transition: all 150ms ease;
  background-color: #fff;
  white-space: nowrap;
}
.action-area .yearButton:hover {
  border-color: rgba(116, 118, 166, 0.6);
}
.action-area .yearButton.is-active {
  border-color: #297AD8;
}
.action-area .yearButton.is-active .yearButton__icon {
  transform: rotate(270deg);
}
.action-area .yearButton.is-disabled {
  background-color: #E1E6ED;
  border-color: rgba(116, 118, 166, 0.15);
  color: rgba(56, 57, 71, 0.35);
  cursor: default;
}
.action-area .yearButton.is-disabled .yearButton__icon {
  color: currentColor;
}
.action-area .yearButton__icon {
  transform: rotate(90deg);
  color: rgba(56, 57, 71, 0.7);
  transition: all 150ms ease;
}
.action-area .year-container {
  position: relative;
}
.action-area .year {
  position: absolute;
  top: 50px;
  z-index: 10;
  width: 100%;
  max-height: 300px;
  border: 1px solid rgba(116, 118, 166, 0.3);
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.15);
  overflow: hidden;
  overflow-y: auto;
}
.action-area .year .item {
  transition: all 150ms ease;
  color: rgba(56, 57, 71, 0.7);
  padding: 11px 16px 14px;
}
.action-area .year .item:hover {
  color: #297AD8;
  background-color: rgba(49, 80, 165, 0.07);
}
.action-area .radioArea {
  padding: 7px;
  background-color: rgba(116, 118, 166, 0.15);
  border: 1px solid rgba(116, 118, 166, 0.2);
}
.action-area .radioArea label {
  width: 80px;
  text-align: center;
  border: 1px solid rgba(116, 118, 166, 0.3);
  background-color: #fff;
  padding: 3px 0 4px;
  cursor: pointer;
  transition: all 150ms ease;
}
.action-area .radioArea label:hover {
  border-color: rgba(116, 118, 166, 0.6);
}
.action-area .radioArea input[type=radio]:checked + label {
  background-color: #F4392C;
  border-color: #F4392C;
  color: #fff;
}
.action-area .radioArea input[type=radio]:disabled + label {
  background-color: #E1E6ED;
  border-color: rgba(116, 118, 166, 0.15);
  color: rgba(56, 57, 71, 0.35);
  cursor: default;
}
html.multiple .action-area .radioArea input[type=radio]:checked + label {
  background-color: #009E77;
  border-color: #009E77;
}
html.multiple .action-area .radioArea input[type=radio]:disabled + label {
  background-color: #E1E6ED;
  border-color: rgba(116, 118, 166, 0.15);
}

#modal-window--tag-list ul {
  border-top: 2px solid #383947;
  border-bottom: 2px solid #383947;
}
#modal-window--tag-list li:not(:first-child) {
  border-top: 1px solid rgba(116, 118, 166, 0.2);
}
#modal-window--tag-list li:not(.is-active) [class*=__active] {
  display: none;
}
#modal-window--tag-list li.is-active {
  background-color: #F2F8FF;
}
#modal-window--tag-list li.is-active [class*=__inactive] {
  display: none;
}
#modal-window--tag-list dt {
  flex-basis: 212px;
  width: 212px;
  flex-shrink: 0;
  white-space: normal;
}
#modal-window--tag-list dt label > span.ui-checkbox__label {
  overflow-wrap: anywhere;
}
#modal-window--tag-list dd {
  width: 100%;
}
#modal-window--tag-list .modal-window__content-textarea {
  height: 132px;
}

.dashboard-title-area:has(.action-area__total-individual) .action-area .action-area__tag {
  max-width: 400px;
  width: 400px;
}

.kgi-summary {
  margin-top: 20px;
  transition: margin 500ms ease;
}
.kgi-summary.is-active {
  margin-top: 0;
}
.kgi-summary.is-active .kgi-summary__content {
  grid-template-rows: 1fr;
}
.kgi-summary.is-active .kgi-summary__btn .icon-chevron-mark {
  transform: rotate(0deg);
}
.kgi-summary.is-active .kgi-summary__btn-text--hide {
  display: inline;
}
.kgi-summary.is-active .kgi-summary__btn-text--open {
  display: none;
}
.kgi-summary__btn-wrapper::before {
  content: "";
  display: block;
  position: absolute;
  border-top: 1px solid rgba(116, 118, 166, 0.2);
  width: calc(100% - 96px);
  top: 12px;
  z-index: 0;
}
.kgi-summary__content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease;
}
.kgi-summary .kgi-summary__content-inner {
  overflow: hidden;
}
.kgi-summary__btn {
  color: #297AD8;
  transition: color 150ms ease;
}
.kgi-summary__btn:hover {
  color: #1C65BA;
}
.kgi-summary__btn .icon-chevron-mark {
  transform: rotate(180deg);
  transition: transform 150ms ease;
}
.kgi-summary__btn-text--hide {
  display: none;
}
.kgi-summary__btn-text--open {
  display: inline;
}

.tabs__btns {
  padding-left: 110px;
  padding-right: 110px;
}
.tabs__btn {
  border: 1px solid #3150A5;
  border-bottom: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #F2F8FF;
  color: #3150A5;
}
.tabs__btn.is-active {
  color: #ffffff;
  background-color: #3150A5;
}
.tabs__content-inner {
  margin-left: 110px;
  margin-right: 110px;
}
.tabs__content-inner.is-quantitative {
  background: url(../img/bg_tabs__content-inner.png) 0 0 repeat-y;
  background-size: 100% auto;
}

.kgi-card.is-parent {
  background-color: #fff;
  border-left-width: 10px !important;
}
.kgi-card.is-child {
  background-color: #F5F7FA;
  margin-left: 8.3272990587%;
}
.kgi-card.is-child .kgi-card__sub {
  flex: 0 0 27.2511848341%;
}
.kgi-card__main {
  flex-grow: 1;
}
.kgi-card__sub {
  flex: 0 0 25.1991310644%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
}
.kgi-card__percent {
  grid-column: 1/3;
}

.tabs__kpi-cards {
  gap: 32px 20px;
  padding: 12px 20px;
}
.tabs__kpi-cards.is-bottom {
  gap: 9px;
  padding: 0 5px;
}

.kpi-card {
  box-shadow: 0 0 0 1px rgba(116, 118, 166, 0.3);
  position: relative;
}
.kpi-card.is-parent .kpi-card__category {
  color: #ffffff;
}
.kpi-card.is-parent .kpi-card__category::before {
  background-color: #B040A5;
}
.kpi-card.is-child {
  background-color: #F5F7FA;
}
.kpi-card.is-child .kpi-card__category {
  color: #3150A5;
}
.kpi-card.is-child .kpi-card__category::before {
  background-color: rgba(49, 80, 165, 0.07);
}
.kpi-card.is-start::before, .kpi-card.is-end::after {
  content: "";
  display: block;
  position: absolute;
  top: -12px;
  width: 13px;
  height: calc(100% + 24px);
  border-top: 1px solid rgba(116, 118, 166, 0.2);
  border-bottom: 1px solid rgba(116, 118, 166, 0.2);
}
.kpi-card.is-start::before {
  border-left: 6px solid rgba(116, 118, 166, 0.2);
  left: -13px;
}
.kpi-card.is-end::after {
  border-right: 6px solid rgba(116, 118, 166, 0.2);
  right: -13px;
}
.kpi-card__category {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.kpi-card__category::before {
  content: "";
  display: block;
  position: absolute;
  inset: -1px -1px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.kpi-card__body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
}
.kpi-card__percent {
  grid-column: 1/3;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.kpi-card__percent::after {
  font-family: "icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 32px;
}
.kpi-card__percent.is-good::after {
  content: "\e91f";
  color: #00BA56;
}
.kpi-card__percent.is-not-good::after {
  content: "\e91f";
  color: #F23939;
  transform: rotateX(180deg) translateY(-3px);
}

.box-file-upload {
  background: #F5F7FA;
  border: 4px solid rgba(116, 118, 166, 0.2);
  border-radius: 16px;
  cursor: pointer;
}

.btn-select-file {
  background: #FFFFFF;
  border: 1px solid rgba(116, 118, 166, 0.3);
  border-radius: 4px;
  padding: 3px 11px 4px;
}

.data-table {
  background-color: #fff;
}
.data-table thead {
  background: #E1E6ED;
  position: relative;
}
.data-table thead + tbody {
  border-top: none;
}
.data-table thead::after {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid rgba(116, 118, 166, 0.2);
}
.data-table thead th {
  padding: 12px 20px 13px;
  text-align: left;
  position: relative;
}
.data-table thead th::after {
  content: "";
  display: block;
  position: absolute;
  top: 14px;
  bottom: 12px;
  right: 0;
  width: 1px;
  background-color: rgba(116, 118, 166, 0.3);
}
.data-table thead th:last-child::after {
  content: none;
}
.data-table tbody {
  border-top: 1px solid rgba(116, 118, 166, 0.2);
}
.data-table tbody td {
  padding: 5px 20px 6px;
  border-bottom: 1px solid rgba(116, 118, 166, 0.2);
}
.data-table tbody td:nth-child(1) {
  width: 24.7448979592%;
}
.data-table tbody td:nth-child(2) {
  width: 59.1836734694%;
}
.data-table tbody td:nth-child(3) {
  width: 16.0714285714%;
  text-align: right;
}

#modal-window--upload-confirm {
  width: 860px;
}
#modal-window--upload-confirm .modal-window__content .box-xy-bordered {
  max-height: calc(100vh - 300px);
  min-height: 300px;
}
#modal-window--upload-confirm .modal-window__content .data-table td:nth-child(1),
#modal-window--upload-confirm .modal-window__content .data-table td:nth-child(3) {
  width: 40%;
}
#modal-window--upload-confirm .modal-window__content .data-table td:nth-child(2) {
  width: 10%;
}
#modal-window--upload-confirm .modal-window__content [class^=box-upload] [class^=icon] {
  font-size: 32px;
}
#modal-window--upload-confirm .modal-window__upload-result {
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.15);
}

.dashboard-table {
  border-top: 2px solid #383947;
  border-bottom: 2px solid #383947;
  background-color: #fff;
  border-spacing: 0;
}
.dashboard-table thead {
  background-color: rgba(116, 118, 166, 0.2);
  border-bottom: 1px solid rgba(116, 118, 166, 0.2);
}
.dashboard-table thead th {
  text-align: left;
  position: relative;
}
.dashboard-table tbody td {
  border-bottom: 1px solid rgba(116, 118, 166, 0.2);
  position: relative;
}
.dashboard-table tbody tr:last-child td {
  border-bottom: none;
}
.dashboard-table th, .dashboard-table td {
  padding-right: 32px;
}
.dashboard-table th:first-child, .dashboard-table td:first-child {
  padding-left: 32px;
}
.dashboard-table__th--preview::before, .dashboard-table__th--badge::before, .dashboard-table__th--tag::before, .dashboard-table__th--bookmark::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -16px;
  width: 1px;
  height: 16px;
  background-color: rgba(116, 118, 166, 0.2);
}
.dashboard-table__td--name-1 {
  width: 13.25%;
}
.dashboard-table__td--name-2 {
  width: 11.25%;
}
.dashboard-table__td--name-3 {
  width: 13.25%;
}
.dashboard-table__td--name-3.is-disabled {
  pointer-events: none;
}
.dashboard-table__td--name-3.is-disabled .text-link {
  color: rgba(56, 57, 71, 0.35);
}
.dashboard-table__td--preview {
  width: 17%;
}
.dashboard-table__td--preview::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -16px;
  width: 1px;
  height: 16px;
  background-color: rgba(116, 118, 166, 0.2);
}
.dashboard-table__td--badge {
  width: 9.5%;
}
.dashboard-table__td--badge::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -16px;
  width: 1px;
  height: 16px;
  background-color: rgba(116, 118, 166, 0.2);
}
.dashboard-table__td--tag {
  width: 15.3125%;
}
.dashboard-table__td--tag::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -16px;
  width: 1px;
  height: 16px;
  background-color: rgba(116, 118, 166, 0.2);
}
.dashboard-table__td--bookmark {
  width: 20.4375%;
}
.dashboard-table__td--bookmark::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -16px;
  width: 1px;
  height: 16px;
  background-color: rgba(116, 118, 166, 0.2);
}
.dashboard-table__td--bookmark .icon-bookmark-container {
  width: 46px;
  height: 46px;
  border: 1px solid rgba(116, 118, 166, 0.3);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-img img {
  width: 240px;
}

.badge-list__item:nth-child(n+2) {
  margin-top: 4px;
}
.badge-list__item img {
  vertical-align: top;
  height: 24px;
}

.tag-list__item:not(:first-child):before {
  content: "、";
}

.tag-memo-btn {
  position: relative;
  color: rgba(255, 77, 91, 0.9);
}
.tag-memo-btn:disabled {
  color: rgba(56, 57, 71, 0.45);
  pointer-events: none;
}
.tag-memo-btn:hover .box-tag-memo {
  opacity: 1;
}

.box-tag-memo {
  opacity: 0;
  position: absolute;
  top: calc(1.8em + 8px);
  left: 50%;
  transform: translateX(-50%);
  width: 272px;
  border: 1px solid rgba(56, 57, 71, 0.45);
  background-color: #fff;
  padding: 14.39px 16px 20px;
  color: #3150A5;
  text-align: left;
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.15);
  z-index: 2;
  border-radius: 8px;
  pointer-events: none;
  transition: opacity 150ms ease;
}
.box-tag-memo::before {
  position: absolute;
  display: block;
  content: "";
  background: url(../img/bg_nav-graph__preview.png) no-repeat 0 0;
  background-size: contain;
  width: 11px;
  height: 10px;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.dashboard-filter {
  height: 70px;
}
.dashboard-filter:has(.save-box) .dashboard-filter-box {
  max-width: 1078px;
}
.dashboard-filter:has(.save-box) .dashboard-filter-box.button-box {
  max-width: 258px;
}
.dashboard-filter:has(.save-box) .dashboard-filter-box.save-box {
  max-width: none;
}
.dashboard-filter:has(.save-box) .dashboard-filter-box-inner.tags {
  max-width: 800px; /* .bookmarkあり */
}
.dashboard-filter:has(.save-box) .dashboard-filter-box-inner.tags.nobm { /* .bookmark なし */
  max-width: 953px;
}
.dashboard-filter:has(.save-box) .dashboard-filter-box-inner.tags.nobm .usage { /* 用途タグ ul */
  max-width: 965px;
}
.dashboard-filter h6 {
  min-width: 88px;
}
.dashboard-filter-box {
  width: fit-content;
  max-width: 1158px;
  flex: 1 1 100%;
  min-width: 0;
  justify-content: flex-start;
}
.dashboard-filter-box.button-box {
  background-color: transparent;
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, 0.5);
  max-width: 258px;
  margin-left: auto;
}
.dashboard-filter-box.save-box {
  background-color: transparent;
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, 0.5);
  margin-left: 24px;
  flex: 0 0 56px;
  padding-top: 7px;
  line-height: 1;
}
.dashboard-filter-box.save-box .btn-save-filter-conditions {
  font-size: 32px;
  line-height: 1;
  color: #fff;
  transition: opacity 150ms ease;
}
.dashboard-filter-box.save-box .btn-save-filter-conditions:hover {
  opacity: 0.7;
}
.dashboard-filter-box.save-box .btn-save-filter-conditions:disabled {
  opacity: 0.35;
}
.dashboard-filter-box p {
  min-width: 106px;
}
.dashboard-filter-box-inner {
  min-height: 42px;
  width: fit-content;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 23px;
}
.dashboard-filter-box-inner.tags {
  width: fit-content;
  max-width: 880px; /* .bookmarkあり[件数非表示時 986px] */
  position: relative;
  white-space: nowrap;
  background-color: #f2f8ff;
}
.dashboard-filter-box-inner.tags.nobm { /* .bookmark なし[件数非表示時 1163px] */
  max-width: 1057px;
  /* 件数表示 （ 156 / 156 ）をしない場合
    max-width: 986px;
      &.nobm {  
        max-width: 1163px; 
    } */
}
.dashboard-filter-box-inner.tags.nobm .usage { /* 用途タグ ul */
  max-width: 1045px;
}
.dashboard-filter-box-inner.tags.nobm .usage.nobadge {
  width: fit-content;
}
.dashboard-filter-box-inner.tags.nobm .badges { /* バッジ ul */
  max-width: 256.16px;
}
.dashboard-filter-box-inner.tags ul {
  overflow: hidden;
}
.dashboard-filter-box-inner.tags ul .over {
  overflow: hidden;
  width: max(5rem, 28px);
  text-overflow: ellipsis;
}
.dashboard-filter-box-inner.tags ul .usage-only {
  width: unset;
}
.dashboard-filter-box-inner.tags ul.usage { /* 用途タグ ul */
  max-width: 864.75px;
}
.dashboard-filter-box-inner.tags ul.badges { /* バッジ ul */
  max-width: 214.16px;
}
.dashboard-filter-box-inner-list {
  width: fit-content;
  border: 1px solid #ABCDF5;
  border-radius: 100px;
  background-color: #fff;
}
.dashboard-filter-box-inner-list .add-comma:after {
  content: "、";
}
.dashboard-filter-box-inner-list.bookmark {
  border: 0;
  width: 152px;
}
.dashboard-filter-box-inner-list.usage:hover + .dashboard-filter-selected-items, .dashboard-filter-box-inner-list.badges:hover + .dashboard-filter-selected-items {
  opacity: 1;
}
.dashboard-filter-box-inner .dashboard-filter-selected-items {
  opacity: 0;
  position: absolute;
  bottom: 46px;
  width: 336px;
  border: 1px solid rgba(56, 57, 71, 0.45);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.15);
  border-radius: 8px;
  pointer-events: none;
  transition: opacity 150ms ease;
  white-space: normal;
  font-size: 14px;
  line-height: 1.8;
  color: #3150A5;
}
.dashboard-filter-box-inner .dashboard-filter-selected-items::before {
  position: absolute;
  display: block;
  content: "";
  background: url(../img/bg_nav-graph__preview.png) no-repeat 0 0;
  background-size: contain;
  width: 11px;
  height: 10px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}
.dashboard-filter-box-inner span {
  width: max-content;
  max-width: 66px;
}
.dashboard-filter-box-inner.buttons {
  background-color: transparent;
  border-radius: 0;
  min-width: 226px;
  justify-content: flex-end;
  margin-left: 24px;
  margin-right: 0;
}
.dashboard-filter-box-inner.buttons .filter-btn {
  width: 146px;
  height: 46px;
  background: #297AD8;
  border-radius: 8px;
  padding: 3px 0 4px;
}
.dashboard-filter-box-inner.buttons .filter-btn:hover {
  background: rgba(41, 122, 216, 0.7);
}
.dashboard-filter-box-inner.buttons .text-link {
  color: #80D1FF;
}

.modal-window--dashboard-filter .icon-bookmark-off {
  font-size: 1.5rem;
}
.modal-window--dashboard-filter .divider {
  position: relative;
}
.modal-window--dashboard-filter .divider:before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(116, 118, 166, 0.2);
  position: absolute;
  top: 50%;
}
.modal-window--dashboard-filter .btn-group-container {
  filter: drop-shadow(0px 8px 24px rgba(116, 118, 166, 0.1));
}
.modal-window--dashboard-filter .btn-group-container > label {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  line-height: 1.5em;
  background-color: #fff;
  border: solid 1px #ABCDF5;
  color: #297AD8;
}
.modal-window--dashboard-filter .btn-group-container > label:first-of-type {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.modal-window--dashboard-filter .btn-group-container > label:last-of-type {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
.modal-window--dashboard-filter .btn-group-container .btn:hover {
  background-color: #73747e !important;
  color: #fff;
}
.modal-window--dashboard-filter .btn-group-container input[type=radio]:checked + label {
  background-color: #297AD8;
  border-color: #297AD8;
  color: #fff;
}

.setting-table {
  position: relative;
}
.setting-table.is-mid-term-plan .setting-table__table thead th {
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
}
.setting-table.is-mid-term-plan .setting-table__table thead th:first-child {
  width: auto;
}
.setting-table.is-mid-term-plan .setting-table__table thead .setting-table__col-kgi-term {
  width: 254px;
}
.setting-table.is-mid-term-plan .setting-table__table thead .setting-table__col-kgi-single-multiple,
.setting-table.is-mid-term-plan .setting-table__table thead .setting-table__col-kgi-condition {
  width: 95px;
}
.setting-table.is-mid-term-plan .setting-table__table thead .setting-table__col-kgi-last-modify-person {
  width: 320px;
}
.setting-table.is-mid-term-plan .setting-table__table thead .setting-table__col-kgi-last-modify-date {
  width: 160px;
}
.setting-table.is-mid-term-plan .setting-table__table tbody th, .setting-table.is-mid-term-plan .setting-table__table tbody td {
  padding: 10px 16px;
  text-align: left;
}
.setting-table.is-mid-term-plan .setting-table__table tbody th a, .setting-table.is-mid-term-plan .setting-table__table tbody td a {
  color: #297AD8;
}
.setting-table.is-mid-term-plan .setting-table__table tbody th a:hover, .setting-table.is-mid-term-plan .setting-table__table tbody td a:hover {
  color: rgba(41, 122, 216, 0.7);
}
.setting-table.is-mid-term-plan .setting-table__table tbody th.is-disabled, .setting-table.is-mid-term-plan .setting-table__table tbody td.is-disabled {
  color: rgba(56, 57, 71, 0.35);
}
.setting-table.is-mid-term-plan .setting-table__table tbody th {
  font-weight: 500;
}
.setting-table.is-mid-term-plan .setting-table__table tbody td {
  font-weight: 400;
}
.setting-table.is-mid-term-plan th:nth-child(n+2),
.setting-table.is-mid-term-plan td:nth-child(n+2) {
  border-left: 1px solid rgba(116, 118, 166, 0.2);
}
.setting-table.is-kgi {
  container: layout inline-size;
}
.setting-table.is-kgi .setting-table__table thead {
  border-top: 2px solid #abaeca;
  border-bottom: 1px solid #abaeca;
}
.setting-table.is-kgi .setting-table__table thead th:first-child {
  width: 13rem;
}
.setting-table.is-kgi .setting-table__table thead th:nth-child(3) {
  padding-left: 32px;
}
.setting-table.is-kgi .setting-table__table tbody th, .setting-table.is-kgi .setting-table__table tbody td {
  border-bottom: 1px solid #d8dae8;
}
.setting-table.is-kgi .setting-table__gradation--left {
  left: 520px;
  border-left-color: #d8dae8;
}
.setting-table.is-kgi .setting-table__btn-scroll-left {
  left: 520px;
}
.setting-table.is-kgi .select-control__select {
  min-width: 80px;
}
.setting-table.is-kgi .setting-table__scroll {
  width: calc(100vw - 256px - 2px);
}
@media (min-width: 1728px) {
  .setting-table.is-kgi .setting-table__scroll {
    width: 1470px;
  }
}
.setting-table.is-kgi .setting-table__thead-col-title {
  padding-left: 16px;
  padding-right: 0;
  text-align: left;
}
.setting-table.is-kgi .setting-table__thead-col-target {
  padding-left: 96px;
}
.setting-table.is-kgi .setting-table__thead-col-target::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #cccfe1;
}
.setting-table.is-kgi .setting-table__col-title a {
  color: #297AD8;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 0;
  flex-shrink: 0;
}
.setting-table.is-kgi .setting-table__col-title a:hover {
  color: rgba(41, 122, 216, 0.7);
}
.setting-table.is-kgi .setting-table__col-title.is-parent {
  padding: 0 !important;
}
.setting-table.is-kgi .setting-table__col-title.is-parent .kgi-title {
  border-left: 6px solid #297AD8;
  width: 312px;
}
.setting-table.is-kgi .setting-table__col-title.is-parent .kgi-title a {
  flex-basis: 280px;
}
.setting-table.is-kgi .setting-table__col-title.is-child {
  padding: 0 !important;
  padding-left: 32px !important;
  background-color: #f8f9fc;
}
.setting-table.is-kgi .setting-table__col-title.is-child .kgi-title {
  background-color: #f1f3f9;
  border-left: 2px solid #297AD8;
  width: 280px;
}
.setting-table.is-kgi .setting-table__col-title.is-child .kgi-title a {
  flex-basis: 248px;
}
.setting-table.is-kgi .setting-table__col-title.is-quantitative .kgi-title {
  height: 88px;
}
.setting-table.is-kgi .setting-table__col-title.is-quantitative + .setting-table__col-target {
  border-bottom-color: #eaebf1;
}
.setting-table.is-kgi .setting-table__col-title.is-quantitative ~ td {
  border-bottom-color: #e6e7ee;
}
.setting-table.is-kgi .setting-table__col-title.is-qualitative .kgi-title {
  height: 51px;
}
.setting-table.is-kgi .setting-table__col-target::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #d8dae8;
}
.setting-table.is-data-update-status .setting-table__table thead {
  border-top: 2px solid #abaeca;
  border-bottom: 1px solid #abaeca;
}
.setting-table.is-data-update-status .setting-table__table thead th {
  text-align: left;
  padding: 8px 16px;
}
.setting-table.is-data-update-status .setting-table__table thead th:nth-child(n+2) {
  border-left: 1px solid #d8dae8;
}
.setting-table.is-data-update-status .setting-table__table tbody th, .setting-table.is-data-update-status .setting-table__table tbody td {
  border-bottom: 1px solid #d8dae8;
}
.setting-table.is-data-update-status .setting-table__table tbody th:nth-child(n+2), .setting-table.is-data-update-status .setting-table__table tbody td:nth-child(n+2) {
  border-left: 1px solid #d8dae8;
}
.setting-table.is-data-update-status .setting-table__table tbody th {
  padding: 8px 16px;
  text-align: left;
}
.setting-table.is-data-update-status .setting-table__table tbody td {
  padding: 8px 16px;
  text-align: center;
}
.setting-table.is-data-update-status .setting-table__col-name {
  width: 25.5747126437%;
}
.setting-table.is-data-update-status .setting-table__col-monthly,
.setting-table.is-data-update-status .setting-table__col-annual {
  width: 21.0488505747%;
}
.setting-table.is-data-update-status .setting-table__col-quarter {
  width: 23.132183908%;
}
.setting-table.is-benchmark.is-search-result {
  max-height: 383.6px;
  overflow-y: auto;
}
.setting-table.is-benchmark .setting-table__table {
  border-collapse: separate;
  border-spacing: 0;
}
.setting-table.is-benchmark .setting-table__table thead {
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.setting-table.is-benchmark .setting-table__table thead th {
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  border-top: 2px solid #d5d6e5;
  border-bottom: 1px solid #d5d6e5;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-name {
  width: 21.5517241379%;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-classification {
  width: 14.367816092%;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-target {
  width: 7.9022988506%;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-registrant {
  width: 12.2126436782%;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-last-update {
  width: 12.2126436782%;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-setting-btn {
  width: 50px;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-setting-company-number {
  width: 14.367816092%;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-setting-classification {
  width: 8.6206896552%;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-setting-address {
  width: 17.9597701149%;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-setting-scale {
  width: 14.367816092%;
}
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-setting-women-empowerment,
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-setting-finance {
  width: 7.183908046%;
}
.setting-table.is-benchmark .setting-table__table tbody th, .setting-table.is-benchmark .setting-table__table tbody td {
  padding: 10px 16px;
  text-align: left;
}
.setting-table.is-benchmark .setting-table__table tbody th a, .setting-table.is-benchmark .setting-table__table tbody td a {
  color: #297AD8;
}
.setting-table.is-benchmark .setting-table__table tbody th a:hover, .setting-table.is-benchmark .setting-table__table tbody td a:hover {
  color: rgba(41, 122, 216, 0.7);
}
.setting-table.is-benchmark .setting-table__table tbody th.is-disabled, .setting-table.is-benchmark .setting-table__table tbody td.is-disabled {
  color: rgba(56, 57, 71, 0.35);
}
.setting-table.is-benchmark .setting-table__table tbody th {
  font-weight: 500;
}
.setting-table.is-benchmark .setting-table__table tbody td {
  font-weight: 400;
}
.setting-table.is-benchmark .setting-table__table tbody tr.is-selected td {
  color: rgba(56, 57, 71, 0.45);
}
.setting-table.is-benchmark .setting-table__table tbody tr.is-targeted td {
  animation: itemDropped 1.5s linear 1 alternate;
}
@keyframes itemDropped {
  0% {
    background-color: rgba(49, 80, 165, 0.15);
  }
  to {
    background-color: rgba(49, 80, 165, 0.03);
  }
}
.setting-table.is-benchmark th:nth-child(n+2),
.setting-table.is-benchmark td:nth-child(n+2) {
  border-left: 1px solid rgba(116, 118, 166, 0.2);
}
.setting-table__gradation--left {
  position: absolute;
  display: block;
  width: 16px;
  height: calc(100% - 35px);
  left: 340px;
  bottom: 0;
  border-left: 1px solid #e9ebf1;
  background: linear-gradient(90deg, rgba(116, 118, 166, 0.05) 0%, rgba(116, 118, 166, 0) 100%) repeat-y 0 0/16px 100%;
}
.setting-table__gradation--right {
  position: absolute;
  display: block;
  width: 32px;
  height: calc(100% - 35px);
  right: 0;
  bottom: 0;
  background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0) 100%);
}
.setting-table__btn-scroll-left, .setting-table__btn-scroll-right {
  background-color: #297AD8 !important;
}
.setting-table__btn-scroll-left:hover, .setting-table__btn-scroll-right:hover {
  transition: background-color 150ms ease;
  background-color: #6fa2e5 !important;
}
.setting-table__btn-scroll-left:disabled, .setting-table__btn-scroll-right:disabled {
  background-color: #d0d5e6 !important;
}
.setting-table__btn-scroll-left {
  top: 2px;
  left: 340px;
}
.setting-table__btn-scroll-right {
  top: 2px;
  right: 0;
}
.setting-table__table thead {
  border-top: 2px solid #d5d6e5;
  border-bottom: 1px solid #d5d6e5;
  color: #7476A6;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.setting-table__table thead th {
  padding: 6.5px 0 7.5px;
  background-color: #e0e5f2;
}
.setting-table__table thead th:first-child {
  text-align: right;
  padding-right: 44px;
  width: 340px;
}
.setting-table__table thead th:nth-child(2) {
  padding-left: 32px;
}
.setting-table__table thead th:last-child {
  padding-right: 32px;
}
.setting-table__table tbody th, .setting-table__table tbody td {
  border-bottom: 1px solid #e9ebf1;
}
.setting-table__table tbody th {
  white-space: nowrap;
  padding: 4px 16px;
  background-color: #f1f3f9;
}
.setting-table__table tbody td {
  background-color: rgba(49, 80, 165, 0.03);
  padding: 4px 8px;
  text-align: center;
}
.setting-table__table tbody td:first-of-type {
  padding-left: 32px;
}
.setting-table__table tbody td:last-of-type {
  padding-right: 32px;
}

.setting-heading {
  width: 308px;
}
.is-kgi .setting-heading {
  width: 176px;
}
.setting-heading__name {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-right: 8px;
}
.setting-heading__name a {
  color: #297AD8;
}
.setting-heading__name a:hover {
  color: rgba(41, 122, 216, 0.7);
}
.setting-heading__name.is-disabled {
  color: rgba(56, 57, 71, 0.35);
}
.setting-heading__unit {
  margin-left: auto;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #3150A5;
  margin-right: 8px;
}
.setting-heading__unit.is-disabled {
  color: rgba(56, 57, 71, 0.35);
}
.setting-heading__select:disabled {
  color: rgba(56, 57, 71, 0.35);
}

.select-list {
  position: relative;
}
.select-list__btn {
  cursor: pointer;
  min-width: 104px;
  border: 1px solid rgba(116, 118, 166, 0.3);
  transition: all 150ms ease;
  background-color: #fff;
  white-space: nowrap;
}
.select-list__btn:hover {
  border-color: rgba(116, 118, 166, 0.6);
}
.select-list__btn.is-active {
  border-color: #297AD8;
}
.select-list__btn.is-active .yearButton__icon {
  transform: rotate(270deg);
}
.select-list__btn.is-disabled {
  background-color: #E1E6ED;
  border-color: rgba(116, 118, 166, 0.15);
  color: rgba(56, 57, 71, 0.35);
  cursor: default;
}
.select-list__btn.is-disabled .yearButton__icon {
  color: currentColor;
}
.select-list__btn-icon {
  transform: rotate(90deg);
  color: rgba(56, 57, 71, 0.7);
  transition: all 150ms ease;
}
.select-list__list {
  position: absolute;
  top: 77px;
  z-index: 20;
  left: 220px;
  width: 104px;
  max-height: 300px;
  border: 1px solid rgba(116, 118, 166, 0.3);
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(116, 118, 166, 0.15);
  overflow: hidden;
  overflow-y: auto;
  text-align: left;
}
.select-list__list-item {
  transition: all 150ms ease;
  color: rgba(56, 57, 71, 0.7);
  padding: 6px 14px 10px;
}
.select-list__list-item:hover {
  color: #297AD8;
  background-color: rgba(49, 80, 165, 0.07);
}

.select-control {
  position: relative;
}
.select-control__select {
  cursor: pointer;
  min-width: 104px;
  border: 1px solid rgba(116, 118, 166, 0.3);
  transition: all 150ms ease;
  background-color: #fff;
  white-space: nowrap;
  padding: 7px 14px 7px 14px;
  appearance: none;
  outline: none;
}
.select-control__select:hover {
  border-color: rgba(116, 118, 166, 0.6);
}
.select-control__select:disabled {
  color: rgba(56, 57, 71, 0.35);
  border-color: rgba(56, 57, 71, 0.35);
}
.select-control__icon {
  color: rgba(56, 57, 71, 0.7);
  transition: all 150ms ease;
}
.select-control__icon span.is-disabled {
  color: rgba(56, 57, 71, 0.35);
}

.text-input {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  border-radius: 8px;
  background-color: #fff;
  padding: 7px 14px 8px 14px;
  box-shadow: 0 0 1px rgba(116, 118, 166, 0.3);
  transition: box-shadow 150ms ease;
  width: 114px;
  outline: none;
}
.text-input:disabled {
  background-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(116, 118, 166, 0.3);
}
.text-input:placeholder-shown {
  color: rgba(56, 57, 71, 0.35);
  text-align: left;
}
.text-input:focus {
  box-shadow: 0 0 1px rgba(116, 118, 166, 0.6);
  background-color: rgba(116, 118, 166, 0.15);
}

label.ui-radio.single {
  border-bottom: 3px solid #f4392c;
  padding-bottom: 6px;
}
label.ui-radio.multiple {
  border-bottom: 3px solid #009e77;
  padding-bottom: 6px;
}

hr.kpisetting {
  border-top-width: 3px;
}
hr.kpisetting.single {
  background-color: #f4392c;
  border-color: #f4392c;
}
hr.kpisetting.multiple {
  background-color: #009e77;
  border-color: #009e77;
}

.box-bordered-heading {
  position: relative;
}
.box-bordered-heading::before {
  content: "";
  display: block;
  position: absolute;
  left: 26px;
  top: 15px;
  bottom: 15px;
  background-color: #80D1FF;
  width: 6px;
  border-radius: 3px;
}
.multiple .box-bordered-heading::before {
  background-color: #fff;
}
.box-bordered-heading.is-green {
  color: #fff;
  background-color: #009E77;
}
.box-bordered-heading.is-green::before {
  background-color: #fff;
}
.box-bordered-heading.is-grey {
  color: #fff;
  background-color: rgba(56, 57, 71, 0.7);
}
.box-bordered-heading.is-grey::before {
  background-color: #fff;
}

.back-upper-level__link:hover {
  color: rgba(41, 122, 216, 0.7) !important;
}

.bg-modal-window {
  display: none;
  transition: opacity 150ms ease;
}
.bg-modal-window.is-active {
  display: block;
}

.modal-window {
  display: none;
  width: 480px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 8px 24px rgba(56, 57, 71, 0.2));
}
.modal-window.is-active {
  display: block;
}
.modal-window .icon-close {
  font-size: 32px;
}
.modal-window__content-textarea {
  height: 220px;
}
.modal-window__btns .flex .btn {
  padding-left: 0;
  padding-right: 0;
  flex: 1 0 0%;
}

.modal-window__wide {
  grid-template-rows: auto 1fr;
  max-height: 100%;
  max-height: 100svh;
  width: 960px;
}
.modal-window__wide.is-w1120 {
  width: 1120px;
}
.modal-window__wide.is-w1456 {
  width: 1456px;
}
.modal-window__wide.is-active {
  display: grid;
}
.modal-window__wide .modal-window__content {
  overflow-y: auto;
  overscroll-behavior: none;
}

.modal-window--analysis-setting {
  width: 1168px;
}
.modal-window--analysis-setting .checkbox-list__item {
  margin-bottom: 4px;
}
.modal-window--analysis-setting .checkbox-list__item {
  padding-top: 4px;
  padding-bottom: 3px;
}
.modal-window--analysis-setting .divider {
  height: 1px;
  background-color: rgba(116, 118, 166, 0.2);
  margin: 59px 0;
  position: relative;
}
.modal-window--analysis-setting .divider__title {
  font-size: 18px;
  color: #297AD8;
  width: 132px;
  height: 46px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-window--analysis-setting .modal-window__filter-detail-content {
  max-height: calc(100vh - 72px - 88px - 80px - 32px - 60px - 98px);
}
.modal-window--analysis-setting .modal-window__filter-detail-content.is-no-vertical-padding {
  max-height: calc(100vh - 72px - 88px - 80px - 32px - 60px - 98px - 40px - 26px);
}
