.chrofy-hide {
  display: none !important;
}

html.multiple .hide-subheader-wrapper .page-container {
  padding-top: 100px !important;
}

#root>div>div>div>div>div>form>div>button {
  background-color: hsl(190, 95%, 30%);
}

#root>div>div>div>div>div>form>div>button:hover {
  background-color: hsl(190, 95%, 20%);
}

#root>div>div>div>div>div>form>div>.amplify-button--loading {
  background-color: hsl(195, 10%, 90%);
  /*background-color: transparent;*/
}

#root>div>div>div>div>div>form>div>.amplify-button--loading:hover {
  background-color: hsl(195, 10%, 90%);
}

#root>div>div>div>form>fieldset>div>.amplify-button--primary {
  background-color: hsl(190, 95%, 30%);
}

#root>div>div>div>form>fieldset>div>.amplify-button--primary:hover {
  background-color: hsl(190, 95%, 20%);
}

#root>div>div>div>form>fieldset>div>.amplify-button--disabled {
  background-color: transparent;
}

.amplify-input {
  border: 1px solid rgb(118, 118, 118) !important;
}

.loginLink {
  color: blue;
  text-decoration: underline;
}

.link-pointer {
  cursor: pointer;
}

.loginLogo {
  display: inline;
  margin-top: 20px;
}

.loginText {
  text-align: center;
}

.text-h3 {
  white-space: pre-wrap;
}

.text-nowrap {
  white-space: nowrap;
}

.nav-category-empty {
  height: 46px;
}

.sub-title-area {
  background-color: #ECEFF4;
}

/* ダッシュボード高さが外部divを超えたとき円角が表示されない対応 */
#embeddingContainer {
  padding-bottom: 16px;
}

.file-upload-base-style {
  height: 180px;
}

.file-uploading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.animate__fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.animate__fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.6s;
}

.bt-orange {
  background-color: hsl(36, 75%, 46%);
}

.bt-white {
  background-color: hwb(180 98% 1%);
}

.display-none {
  display: none !important;
}

.display-block {
  display: block;
}

.grey-disabled {
  background-color: rgba(56, 57, 71, 0.7) !important;
  border-color: #383947 !important;
}

.btn-mid {
  vertical-align: middle;
  margin: 0 auto;
}

.btn-margin {
  vertical-align: middle;
  margin: 10px 50px 20px;
}

.div-mid {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-init {
  height: 700px;
}

.myds-over {
  overflow: hidden;
  text-overflow: ellipsis;
}

.myds-usage {
  /* 用途タグ ul */
  max-width: 436px;
  /* .bookmarkあり[件数非表示時 704px][件数表示時 598px] */
}

.myds-usage:hover+.dashboard-filter-selected-items {
  opacity: 1;
}

.myds-usage-nobadge {
  /* 用途タグ ul nobadge*/
  max-width: 680px;
  /* .bookmarkあり[件数非表示時 948px][件数表示時 842px] */
}

.myds-usage-nobadge:hover+.dashboard-filter-selected-items {
  opacity: 1;
}

.myds-usage-nobm {
  /* 用途タグ ul nobm*/
  max-width: 635.98px;
  /* .bookmarkあり[件数非表示時 884px][件数表示時 635.98px] */
}

.myds-usage-nobm:hover+.dashboard-filter-selected-items {
  opacity: 1;
}

.myds-usage-nobm-nobadge {
  /* 用途タグ ul nobm nobadge*/
  max-width: 891.95px;
  /* .bookmarkあり[件数非表示時 1163px][件数表示時 1057px] */
}

.myds-usage-nobm-nobadge:hover+.dashboard-filter-selected-items {
  opacity: 1;
}

.myds-disp-btn {
  width: 146px;
}

.myds-disp-reset {
  width: 80px;
}

.mydashboard-filter-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 1158px;
  height: 46px;
  flex: 1 1 100%;
  min-width: 0;
  justify-content: flex-start;
}

.mydashboard-filter-box.mybutton-box {
  background-color: transparent;
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, 0.5);
  height: 46px;
  max-width: 320px;
  margin-top: 12px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: -32px;
}

.mydashboard-filter-box.mysave-box {
  background-color: transparent;
  border-left: 1px solid;
  border-color: rgba(255, 255, 255, 0.5);
  height: 46px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 24px;
  flex: 0 0 56px;
  line-height: 1;
}

.mydashboard-filter-box.mysave-box .btn-save-filter-conditions {
  font-size: 32px;
  line-height: 1;
  color: #fff;
  transition: opacity 150ms ease;
  margin-top: 8px;
}

.mydashboard-filter-box.mysave-box .btn-save-filter-conditions:hover {
  opacity: 0.7;
}

.mydashboard-filter-box.mysave-box .btn-save-filter-conditions:disabled {
  opacity: 0.35;
}

.mybuttons {
  height: 46px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.left-usage-disp {
  left: 218px
}

.nav-category__item-load {
  pointer-events: none;
}

.nav-category__btn-load {
  color: #000;
  opacity: 0.75;
  transition: opacity 150ms ease;
  position: relative;
}

.nav-category__btn-load:hover {
  opacity: 0.75;
}

.nav-graph__item-load {
  position: relative;
}

.nav-graph__item-load::before {
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  width: 1px;
  height: 20px;
  background-color: rgba(116, 118, 166, 0.2);
}

.nav-graph__item-load.is-active .nav-graph__btn-load {
  opacity: 1;
  color: #F4392C;
}

.nav-graph__item-load.is-active .nav-graph__btn-load::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 8px;
  right: 8px;
  height: 2px;
  background-color: #F4392C;
}

.nav-graph__btn-load {
  color: #297AD8;
  opacity: 1;
  position: relative;
}

.nav-graph__btn-load:hover .nav-graph__text-load {
  opacity: 1;
}

.nav-graph__btn-load:hover .nav-graph__preview {
  opacity: 1;
}

.my-ds-div {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: calc(2em * 3 * 1.3);
  min-height: calc(12px * 3 * 1.5);
}

.dashboard-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 700px;
}

.table-width {
  width: 682px;
}

.reflesh-animation {
  animation: 1s linear infinite rotation;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-control .form-select.select-p-large {
  padding: 9px 30px 10px 14px;
}

.tabs__btn {
  background-color: #F2F8FF !important;
}

.tabs__btn.is-active {
  background-color: #3150A5 !important;
}

/* == 共通パーツ == */
.content-area+.content-area {
  padding-top: 50px;
}

.box-toast {
  top: 0px !important;
  transform: translate(-50%, -100%);
  transition: transform 0.55s ease !important;
}

.box-toast.is-active {
  transform: translate(-50%, 170%) !important;
}

.display-hover {
  opacity: 0;
}

.display-hover:hover {
  opacity: 1;
}

/* セグメントの有効終了日 */
.badge-segment {
  display: inline-block;
  background: transparent;
  padding: 0.1em 0.5rem;
  font-size: 12px;
  border-radius: 6px;
  border: 0.5px dotted #000000;
  vertical-align: bottom;
  margin-left: 0.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-text {
  cursor: text;
}

.react-datepicker-wrapper .react-datepicker__input-container .react-datepicker__close-icon::after {
  background-color: gray !important;
}

/* 基本テーブル */

.base-table-header {
  margin-left: auto;
}

.base-table-header .title {
  display: flex;
  float: left;
}

.base-table-header .btn-group {
  display: flex;
  float: right;
}

.base-table-header .btn-group .header-btn {
  text-align: center;
  background: #297AD8;
  border-radius: 8px;
  padding: 6px 40px 6px 28px;
}

.base-table-header .btn-group .header-btn:hover {
  background: rgba(41, 122, 216, 0.7) !important;
}

.base-table-header .btn-group .btn:disabled:hover {
  background-color: rgba(56, 57, 71, 0.45) !important;
}

.base-table-header .btn-group .small-btn {
  font-size: 14px;
  padding: 6px 15px;
  align-items: center;
}

.base-table {
  background-color: #fff;
}

.base-table thead th {
  font-size: 12px;
  font-style: normal;
  color: rgb(116, 118, 166);
  background: #e0e5f2;
}

.base-table thead+tbody {
  border-top: none;
}

.base-table thead th {
  padding: 6.5px 16px 7.5px;
  text-align: left;
  white-space: nowrap;
  border-top: 1px solid rgba(116, 118, 166, 0.3);
  border-left: 1px solid rgba(116, 118, 166, 0.3);
  border-right: 1px solid rgba(116, 118, 166, 0.3);
}

.base-table thead th:last-child::after {
  content: none;
}

.base-table tbody {
  border: 1px solid rgba(116, 118, 166, 0.2);
}

.base-table tbody th {
  color: rgb(116, 118, 166);
  background: #E1E6ED;
  padding: 4px 16px;
  border: 1px solid rgba(116, 118, 166, 0.2);
}

.base-table tbody td {
  padding: 4px 16px;
  border: 1px solid rgba(116, 118, 166, 0.2);
}

.base-table tbody td.r-small {
  padding-right: 8px;
}

.base-table tbody td.l-large {
  padding-left: 28px;
}

.base-table tbody td.title {
  background-color: #f1f3f9;
  border-bottom: 1px solid #e9ebf1;
  font-weight: 500;
}

.base-table tbody td.right {
  text-align: right;
}

.base-table tbody td.flex-right {
  display: flex;
  justify-content: flex-end;
}

.base-table .col-align-top {
  vertical-align: top;
}

.base-table .border-none {
  border-left: 0px;
  border-right: 0px;
}

.base-table .table-btn {
  text-align: center;
  background: #297AD8;
  border-radius: 8px;
  padding: 0px 10px 0px;
}

.base-table .table-btn:hover {
  background: rgba(41, 122, 216, 0.7) !important;
}

.base-table .MuiTypography-root {
  font-family: source-han-sans-japanese, sans-serif !important;
  font-size: 16px !important;
  line-height: 2.1 !important;
  font-weight: 400 !important;
}

.base-table tbody td.non-auth-info {
  color: #3150A5;
}

/* セレクトボックスのデザイン変更 */
.form-control .form-select {
  cursor: pointer;
  min-width: 130px;
  border: 1px solid rgba(116, 118, 166, 0.3);
  white-space: nowrap;
  padding: 7px 30px 7px 14px;
  appearance: none;
}

.form-control textarea,
.form-control input[type=text],
.form-control input[type=password],
.form-control input[type=email],
.form-control input[type=tel],
.form-control input[type=url],
.form-control input[type=search] {
  border: 0px !important;
}

.form-control input[type=date] {
  box-shadow: 0 0 0 1px rgba(116, 118, 166, 0.3);
  outline: none;
  transition: box-shadow 150ms ease;
}

.form-control select:placeholder-shown {
  color: rgba(56, 57, 71, 0.35);
}

.form-control select:focus {
  box-shadow: 0 0 0 1px rgba(116, 118, 166, 0.6);
}

.form-control select:disabled {
  background-color: #E1E6ED;
}

.form-control select.is-error {
  color: #F4392C;
  box-shadow: 0 0 0 1px #F4392C;
}

/* 開閉リスト */
.collapse-list .collapse-node.collapse-parent dt {
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: #3150A5;
  background-color: #e0e5f2;
  background-color: #e0e5f2;
  border-top: 1px solid rgba(116, 118, 166, 0.2);
  border-left: 6px solid #297AD8;
  text-wrap: nowrap;
}

.collapse-list .collapse-node.collapse-parent dd {
  overflow: hidden;
  max-height: 0;
  transition: max-height 150ms ease;
}

.collapse-list .collapse-node.collapse-parent.is-open dd.content {
  max-height: var(--height);
}

.collapse-list .collapse-node.collapse-parent .dropdown-button:disabled {
  width: 100%;
  background: url(../img/icon/icon-plus.svg) no-repeat 0 0;
  background-position: top right;
}

.collapse-list .collapse-node.collapse-parent .dropdown-button {
  width: 100%;
  background: url(../img/icon/icon-plus-orange.svg) no-repeat 0 0;
  background-position: top right;
}

.collapse-list .collapse-node.collapse-parent.is-open .dropdown-button {
  width: 100%;
  background: url(../img/icon/icon-minus-orange.svg) no-repeat 0 0;
  background-position: top right;
}

.collapse-list .collapse-node.collapse-child dt {
  background-color: #e0e5f2;
  border-top: 1px solid rgba(116, 118, 166, 0.2);
  border-left: 2px solid #297AD8;
  text-wrap: nowrap;
  height: 38px;
}

.collapse-list .collapse-node.collapse-child dd {
  border-top: 1px solid rgba(116, 118, 166, 0.2);
  overflow: hidden;
  max-height: 0;
  transition: max-height 150ms ease;
}

.collapse-list .collapse-node.collapse-child.is-open dd {
  border-left: 2px solid #297AD8;
  max-height: var(--height);
}

.collapse-list .collapse-node.collapse-child .dropdown-child-button:disabled {
  width: 100%;
  background: url(../img/icon/icon-plus.svg) no-repeat 0 0;
  background-position: right;
}

.collapse-list .collapse-node.collapse-child .dropdown-child-button {
  width: 100%;
  background: url(../img/icon/icon-plus-orange.svg) no-repeat 0 0;
  background-position: right;
}

.collapse-list .collapse-node.collapse-child.is-open .dropdown-child-button {
  width: 100%;
  background: url(../img/icon/icon-minus-orange.svg) no-repeat 0 0;
  background-position: right;
}

.collapse-list .collapse-node .item-center {
  align-items: center;
}

.collapse-list .MuiTypography-root {
  font-family: source-han-sans-japanese, sans-serif !important;
  font-size: 16px !important;
  line-height: 2.1 !important;
  font-weight: 400 !important;
}

.collapse-list .data-upload .MuiTypography-root {
  font-size: 14px !important;
}

/* フッター */
.content-footer {
  margin-top: 50px;
}

.content-footer hr {
  border-top-width: 3px;
  background-color: #f4392c;
  border-color: #f4392c;
}

.content-footer .footer_button_area {
  display: flex;
  justify-content: space-around;
  width: 100%
}

.content-footer .footer_button_area .footer-btn {
  padding: 0px;
  font-weight: 500;
  height: 55px;
  width: 160px;
  text-align: center;
  border-radius: 8px;
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 18px;
  transition: color 150ms ease, background-color 150ms ease;
}

.content-footer .footer_button_area .button_back {
  color: rgba(56, 57, 71, 0.7);
  font-weight: 400;
  border: 1px solid #383947;
  background: #ffffff;
}

.content-footer .footer_button_area .button_back:hover {
  color: #383947;
  background-color: #ffffff !important;
  font-weight: 500;
  border: 2px solid #383947;
}

.content-footer .footer_button_area .button_base {
  color: #ffffff;
  background: #383947;
}

.content-footer .footer_button_area .button_base:hover {
  background-color: #383947b3 !important;
}

.content-footer .footer_button_area .button_base:disabled {
  color: #F2F8FF;
  background-color: #383947b3;
}

.content-footer .footer_button_area .button_delete {
  color: #ffffff;
  background: #f4392c;
}

.content-footer .footer_button_area .button_delete:hover {
  background-color: #ee675d !important;
}

.content-footer .footer_button_area .btn:disabled:hover {
  background-color: rgba(56, 57, 71, 0.45) !important;
}

/* モーダルフッター */
.modal-content-footer .footer_button_area {
  display: flex;
  justify-content: center;
  width: 100%
}

.modal-content-footer .footer_button_area>button {
  margin: 10px;
}

.modal-content-footer .footer_button_area .footer-btn {
  padding: 0px;
  font-weight: 500;
  height: 55px;
  width: 160px;
  text-align: center;
  border-radius: 8px;
  font-family: source-han-sans-japanese, sans-serif;
  font-size: 18px;
  transition: color 150ms ease, background-color 150ms ease;
}

.modal-content-footer .footer_button_area .button_back {
  color: rgba(56, 57, 71, 0.7);
  font-weight: 400;
  border: 1px solid #383947;
  background: #ffffff;
}

.modal-content-footer .footer_button_area .button_back:hover {
  color: #383947;
  background-color: #ffffff !important;
  font-weight: 500;
  border: 2px solid #383947;
}

.modal-content-footer .footer_button_area .button_base {
  color: #ffffff;
  background: #383947;
}

.modal-content-footer .footer_button_area .button_base:hover {
  background-color: #383947b3 !important;
}

.modal-content-footer .footer_button_area .button_base:disabled {
  color: #F2F8FF;
  background-color: #383947b3;
}

.modal-content-footer .footer_button_area .button_delete {
  color: #ffffff;
  background: #f4392c;
}

.modal-content-footer .footer_button_area .button_delete:hover {
  background-color: #ee675d !important;
}

.modal-content-footer .footer_button_area .btn:disabled:hover {
  background-color: rgba(56, 57, 71, 0.45) !important;
}

/* ホーム画面 */
.category-list__item-no-link {
  pointer-events: none;
}

/* サイドメニュー */
.nav__category-item-no-link {
  pointer-events: none;
}

.nav__guideline-item-no-link {
  pointer-events: none;
}

/* サブヘッダー */
.subheader__target-title {
  min-width: 120px;
}

.subheader__target-content {
  min-width: 850px;
}

.subheader-bg-modal-window {
  display: none;
  transition: opacity 150ms ease;
}

.subheader-bg-modal-window.is-active {
  display: block;
}

.box-subheader-toast {
  top: 58px;
  transform: translate(-50%, -200%);
  transition: transform 0.15s ease;
}

.box-subheader-toast.is-active {
  transform: translate(-50%, -100%);
}

.subheader-ui .ui-radio__input:checked+.ui-radio__icon::before {
  border-color: #009E77 !important;
}

.subheader-ui .ui-radio__input:checked+.ui-radio__icon::after {
  background-color: #009E77 !important;
}

/* ダッシュボード.ブックマーク */
.bookmark-bg-modal-window {
  display: none;
  transition: opacity 150ms ease;
}

.bookmark-bg-modal-window.is-active {
  display: block;
}

/* ダッシュボード.カテゴリー */
.tag-bg-modal-window {
  display: none;
  transition: opacity 150ms ease;
}

.tag-bg-modal-window.is-active {
  display: block;
}

/* パスワード強制変更画面 */
#root>div>div>div>form fieldset>button[type="submit"] {
  background-color: hsl(190, 95%, 30%);
}

#root>div>div>div>form fieldset>button[type="submit"]:hover {
  background-color: hsl(190, 95%, 20%);
}

/* 分析データ管理機能 */
.report-list-table thead th {
  text-align: center;
}

.report-list-table thead tr:nth-child(1) th:nth-child(1) {
  width: 135px;
}

.report-list-table thead tr:nth-child(1) th:nth-child(2) {
  width: 90px;
}

.report-list-table thead tr:nth-child(1) th:nth-child(3) {
  width: 185px;
}

.report-list-table thead tr:nth-child(1) th:nth-child(4) {
  width: 375px;
}

.report-list-table thead tr:nth-child(1) th:nth-child(5) {
  width: auto;
}

.report-list-table thead tr:nth-child(1) th:nth-child(6) {
  width: 70px;
}

.report-list-table thead tr:nth-child(2) th:nth-child(1) {
  width: 85px;
}

.report-list-table thead tr:nth-child(2) th:nth-child(2) {
  width: 100px;
}

.report-list-table thead tr:nth-child(2) th:nth-child(3) {
  width: 145px;
}

.report-list-table thead tr:nth-child(2) th:nth-child(4) {
  width: 145px;
}

.report-list-table thead tr:nth-child(2) th:nth-child(5) {
  width: 85px;
}

.report-list-table .MuiTypography-root {
  font-family: source-han-sans-japanese, sans-serif !important;
  font-size: 14px !important;
  line-height: 2.1 !important;
  font-weight: 400 !important;
}

.dmane-data-task-list-table thead th:nth-child(1) {
  width: 130px;
}

.dmane-data-task-list-table thead th:nth-child(2) {
  width: 685px;
}

.dmane-data-task-list-table thead th:nth-child(3) {
  width: 145px;
}

.dmane-data-task-list-table thead th:nth-child(4) {
  width: 90px;
}

.dmane-data-task-list-table thead th:nth-child(5) {
  width: 135px;
}

.data-task-list-table thead th:nth-child(1) {
  width: 130px;
}

.data-task-list-table thead th:nth-child(2) {
  width: 230px;
}

.data-task-list-table thead th:nth-child(3) {
  width: 230px;
}

.data-task-list-table thead th:nth-child(4) {
  width: 80px;
}

.data-task-list-table thead th:nth-child(5) {
  width: 145px;
}

.data-task-list-table thead th:nth-child(6) {
  width: 145px;
}

.data-task-list-table thead th:nth-child(7) {
  width: 90px;
}

.data-task-list-table thead th:nth-child(8) {
  width: 135px;
}

.data-history-table thead th:nth-child(1) {
  width: 20%;
}

.data-history-table thead th:nth-child(2) {
  width: 20%;
}

.data-history-table thead th:nth-child(3) {
  width: 20%;
}

.data-history-table thead th:nth-child(4) {
  width: 20%;
}

.data-history-table thead th:nth-child(5) {
  width: 20%;
}

.data-task-list-table tbody td .middle {
  justify-content: center;
  align-items: center;
}

.data-accounting-list-table tbody td .middle {
  justify-content: center;
  align-items: center;
}

.data-accounting-list-table thead th:nth-child(1) {
  width: 230px;
}

.data-accounting-list-table thead th:nth-child(2) {
  width: 537px;
}

.data-accounting-list-table thead th:nth-child(3) {
  width: 161px;
}

.data-accounting-list-table thead th:nth-child(4) {
  width: 162px;
}

.data-accounting-list-table thead th:nth-child(5) {
  width: 105px;
}

.button_status_error {
  color: blue;
}

.button_file_select {
  color: #297AD8;
  background-color: #FFFFFF;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  width: 35px;
  margin-top: 2px;
}

.button_file_select_disabled {
  color: rgba(56, 57, 71, 0.35);
  background-color: #d0d5e6;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  width: 35px;
}

#upload-modal-table .data-table td:nth-child(1) {
  width: 15%;
}

#upload-modal-table .data-table td:nth-child(2) {
  width: 30%;
}

#upload-modal-table .data-table td:nth-child(3) {
  width: 10%;
}

#upload-modal-table .data-table td:nth-child(4) {
  width: 40%;
}

.upload-error-table tbody {
  border-top: 1px solid rgba(116, 118, 166, 0.2);
  border-bottom: 1px solid rgba(116, 118, 166, 0.2);
}

.setting-table.is-data-update-status tbody td.left {
  text-align: left !important;
}

/* 財務データ登録 */
.centered-container-1600 {
  width: 1600px;
  margin: 0 auto;
}

.accounting-header {
  align-items: center;
}

.accounting-header .accounting-btn-group {
  margin-left: auto;
}

.accounting-header .accounting-btn-group .file-type {
  width: 150px;
  text-align: right;
}

.accounting-header .accounting-btn-group .input-file-type {
  width: 80px;
  border: 1px solid rgba(116, 118, 166, 0.2);
}

.accounting-header .accounting-btn-group .excel-header {
  width: 100px;
  text-align: right;
}

.accounting-header .accounting-btn-group .input-excel-header {
  width: 60px;
  text-align: right;
  border: 1px solid rgba(116, 118, 166, 0.2);
  margin-right: 10px;
}

.accounting-header .accounting-btn-group .input-file {
  width: 280px;
  border: 1px solid rgba(116, 118, 166, 0.2);
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accounting-header .accounting-btn-group .input-file-disabled {
  width: 230px;
  border: 1px solid rgba(116, 118, 166, 0.2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accounting-header .accounting-btn-group .read-btn {
  text-align: center;
  background: #297AD8;
  border-radius: 8px;
  padding: 1px 20px 1px 14px;
}

.accounting-header .accounting-btn-group .read-btn:hover {
  background: rgba(41, 122, 216, 0.7) !important;
}

.accounting-btn-group .btn:disabled:hover {
  background-color: rgba(56, 57, 71, 0.45) !important;
}

.accounting-icon-direction.is-mapping {
  opacity: 0.5;
}

.accounting-account-table tr th:nth-child(1) {
  min-width: 100px;
  max-width: 100px;
}

.accounting-account-table tr th:nth-child(2) {
  min-width: 120px;
  max-width: 120px;
}

.accounting-account-table tr th:nth-child(3) {
  min-width: 100px;
  max-width: 100px;
}

.accounting-account-table tr th:nth-child(4) {
  min-width: 160px;
  max-width: 160px;
}

.accounting-account-table tr td {
  height: 35px;
  border: 1px solid rgba(116, 118, 166, 0.2) !important
}

.accounting-account-table tr td:nth-child(1) {
  min-width: 110px;
  max-width: 110px;
  padding-right: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accounting-account-table tr td:nth-child(3) {
  min-width: 110px;
  max-width: 110px;
  padding-right: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accounting-account-table tr td:nth-child(4) {
  padding-right: 0px;
}

.accounting-description {
  height: 25px;
}

.accounting-file-header {
  margin-top: 0.2rem;
}

.accounting-file-header .file-name {
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accounting-file-header .excel-sheet {
  width: 120px;
  text-align: right;
}

.accounting-file-header .excel-sheet-list {
  width: 120px;
  border: 1px solid rgba(116, 118, 166, 0.2);
}

.setting-table.is-accounting .accounting-table__scroll {
  width: 910px;
  height: 500px;
  max-width: 2000px;
  max-height: 1000px;
  overflow: auto;
  margin-top: 1px;
  background-color: rgba(56, 57, 71, 0.02);
  border: 1px solid rgba(56, 57, 71, 0.1);
}

.accounting-file-table {
  table-layout: fixed;
}

.accounting-file-table thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  resize: horizontal;
  border-top: 2px;
}

.accounting-file-table thead th.is-selected-account {
  background: rgb(248, 255, 148);
  cursor: pointer;
}

.accounting-file-table thead th.is-selected-account.disabled {
  cursor: default;
}

.accounting-file-table thead th.select-account:hover {
  background: rgb(248, 255, 148);
  cursor: pointer;
}

.accounting-file-table thead th.is-selected-amount {
  background: rgb(148, 210, 255);
  cursor: pointer;
}

.accounting-file-table thead th.is-selected-amount.disabled {
  cursor: default;
}

.accounting-file-table thead th.select-amount:hover {
  background: rgb(148, 210, 255);
  cursor: pointer;
}

.accounting-file-table th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  padding: 0px;
}

.accounting-file-table tbody td {
  text-overflow: ellipsis;
  overflow: hidden;
}

.accounting-file-table .draggable-col:hover {
  background: rgb(189, 255, 148)
}

.accounting-file-table .select-row {
  background: rgba(222, 255, 201, 0.7)
}

.accounting-file-table .select-col {
  background: rgb(189, 255, 148)
}

/* 経営計画KGI管理機能 */
.kpi-card__percent {
  min-height: 80px;
}

.kpi-card.is-parent .kpi-card__category__title {
  color: #ffffff;
  background-color: #B040A5;
}

.kpi-card__category__title {
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.kpi-card__category__title::before {
  content: "";
  display: block;
  position: absolute;
  inset: -1px -1px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.form-control .form-select.kgi-select {
  max-width: 400px;
}

.kgi-list-table thead th:nth-child(1) {
  width: auto;
}

.kgi-list-table thead th:nth-child(2) {
  width: 250px;
}

.kgi-list-table thead th:nth-child(3) {
  width: 70px;
}

.kgi-list-table thead th:nth-child(4) {
  width: 90px;
}

.kgi-list-table thead th:nth-child(5) {
  width: auto;
}

.kgi-list-table thead th:nth-child(6) {
  width: 165px;
}

.kgi-list-table-s thead th:nth-child(1) {
  width: auto;
}

.kgi-list-table-s thead th:nth-child(2) {
  width: 250px;
}

.kgi-list-table-s thead th:nth-child(3) {
  width: 90px;
}

.kgi-list-table-s thead th:nth-child(4) {
  width: 250px;
}

.kgi-list-table-s thead th:nth-child(5) {
  width: 165px;
}

.setting-table.is-kgi .indicator-table__scroll {
  width: calc(100vw - 273px - 2px);
  max-width: 1470px;
}

.tree--node--root:has(.tree--node--filetype) .tree--node--label p {
  font-weight: 700;
  font-size: 16px;
}

/* ベンチマーク設定 */
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-setting-women-empowerment,
.setting-table.is-benchmark .setting-table__table thead .setting-table__col-benchmark-setting-finance {
  text-align: center;
}

/* ベンチマーク対象指定条件 */
.w-30 {
  width: 30%;
}

.label_disabled {
  color: rgba(56, 57, 71, 0.35);
}

.radio_checked.radio_disabled.radio_label {
  color: #383947;
}

/* マスタ一覧 */
.setting-table.is-master th:nth-child(n+2),
.setting-table.is-master td:nth-child(n+2) {
  border-left: 1px solid rgba(116, 118, 166, 0.2);
}
.setting-table.is-master .setting-table__table {
  border-collapse: separate;
  border-spacing: 0;
}
.setting-table.is-master .setting-table__table thead {
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.setting-table.is-master .setting-table__table thead th {
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  border-top: 2px solid #d5d6e5;
  border-bottom: 1px solid #d5d6e5;
}
.setting-table.is-master .setting-table__table thead .setting-table__col-master-name {
  width: 20%;
}
.setting-table.is-master .setting-table__table thead .setting-table__col-master-outline {
  width: 50%;
}
.setting-table.is-master .setting-table__table thead .setting-table__col-master-last-update-date {
  width: 15%;
}
.setting-table.is-master .setting-table__table thead .setting-table__col-master-last-update-user {
  width: 15%;
}
.setting-table.is-master .setting-table__table tbody th, .setting-table.is-master .setting-table__table tbody td {
  padding: 10px 16px;
  text-align: left;
}
.setting-table.is-master .setting-table__table tbody th {
  font-weight: 500;
}
.setting-table.is-master .setting-table__table tbody td {
  font-weight: 400;
}

/* セグメント設定 */
.segment-valid-date {
  width: 100%;
  max-width: 130px;
  box-sizing: border-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.setting-table.is-segment th:nth-child(n+2),
.setting-table.is-segment td:nth-child(n+2) {
  border-left: 1px solid rgba(116, 118, 166, 0.2);
}
.setting-table.is-segment .setting-table__table {
  border-collapse: separate;
  border-spacing: 0;
}
.setting-table.is-segment .setting-table__table thead {
  border-top: none;
  border-bottom: none;
  z-index: 2;
}
.setting-table.is-segment .setting-table__table thead th {
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  border-top: 2px solid #d5d6e5;
  border-bottom: 1px solid #d5d6e5;
}
.setting-table.is-segment .setting-table__table thead .setting-table__col-segment-order {
  width: 6%;
  text-align: center !important;
}
.setting-table.is-segment .setting-table__table thead .setting-table__col-segment-code {
  width: 15;
}
.setting-table.is-segment .setting-table__table thead .setting-table__col-segment-name {
  width: 51%;
}
.setting-table.is-segment .setting-table__table thead .setting-table__col-segment-valid-start-date {
  width: 14%;
}
.setting-table.is-segment .setting-table__table thead .setting-table__col-segment-valid-end-date {
  width: 14%;
}
.setting-table.is-segment .setting-table__table tbody th, .setting-table.is-segment .setting-table__table tbody td {
  padding: 10px 16px;
  text-align: left;
}
.setting-table.is-segment .setting-table__table tbody th {
  font-weight: 500;
}
.setting-table.is-segment .setting-table__table tbody td {
  font-weight: 400;
}
.order-icon {
  text-align: center !important;
}
.segment-valid-date .react-datepicker__close-icon {
  display: none;
}

.segment-valid-date.focused .react-datepicker__close-icon {
  display: block;
}

/** ====== Material UI */

.MuiLink-root {
  font-family: source-han-sans-japanese, sans-serif !important;
}

.MuiCheckbox-root {
  width: 16px !important;
  height: 16px !important;
  margin-right: 9px !important;
  padding: 0px !important;
  border-radius: 30% !important;
  background-color: #ffffff !important;
}

.MuiCheckbox-root.Mui-checked {
  color: rgba(255, 77, 91, 0.9) !important;
}

.MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: rgba(255, 77, 91, 0.9) !important;
}

.cheked-green .MuiCheckbox-root.Mui-checked {
  color: #009E77 !important;
}

.cheked-green .MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #009E77 !important;
}

.MuiCheckbox-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(116, 118, 166, 0.2) !important;
}

.MuiCheckbox-root .MuiSvgIcon-fontSizeSmall {
  font-size: 21px !important;
}

.MuiSwitch-switchBase.Mui-checked {
  color: rgba(255, 77, 91, 0.9) !important;
}

.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: rgba(255, 77, 91, 0.9) !important;
}

.MuiRadio-root.Mui-checked {
  color: rgba(255, 77, 91, 0.9) !important;
}

.MuiRadio-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.MuiRadio-root.Mui-checked.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

/** Material UI ====== */