@font-face {
  font-family: 'icon';
  src:
    url('fonts/icon.ttf?fyh6mh') format('truetype'),
    url('fonts/icon.woff?fyh6mh') format('woff'),
    url('fonts/icon.svg?fyh6mh#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-manage-master:before {
  content: "\e92e";
}
.icon-delete:before {
  content: "\e92d";
}
.icon-circle-minus:before {
  content: "\e92b";
}
.icon-circle-plus:before {
  content: "\e92c";
}
.icon-arrow-return:before {
  content: "\e926";
}
.icon-filter-group-segment:before {
  content: "\e927";
}
.icon-filter-group:before {
  content: "\e928";
}
.icon-filter-segment:before {
  content: "\e929";
}
.icon-filter:before {
  content: "\e92a";
}
.icon-search:before {
  content: "\e924";
}
.icon-graph-double:before {
  content: "\e925";
}
.icon-user:before {
  content: "\e922";
}
.icon-group:before {
  content: "\e923";
}
.icon-padlock-close:before {
  content: "\e920";
}
.icon-padlock-open:before {
  content: "\e921";
}
.icon-good:before {
  content: "\e91f";
}
.icon-monitoring:before {
  content: "\e91e";
}
.icon-chevron-mark:before {
  content: "\e91d";
}
.icon-direction:before {
  content: "\e91a";
}
.icon-graph:before {
  content: "\e91b";
}
.icon-person:before {
  content: "\e91c";
}
.icon-plus-line:before {
  content: "\e919";
}
.icon-setting-kgi:before {
  content: "\e917";
}
.icon-setting-kpi:before {
  content: "\e918";
}
.icon-check:before {
  content: "\e914";
}
.icon-error:before {
  content: "\e915";
}
.icon-warning:before {
  content: "\e916";
}
.icon-save:before {
  content: "\e913";
}
.icon-checkbox-indeterminate:before {
  content: "\e911";
}
.icon-checkbox-checked:before {
  content: "\e912";
}
.icon-badge-outline:before {
  content: "\e90d";
}
.icon-memo:before {
  content: "\e90e";
}
.icon-tag-fill:before {
  content: "\e90f";
}
.icon-tag-outline:before {
  content: "\e910";
}
.icon-mydashboard:before {
  content: "\e90c";
}
.icon-bookmark-off:before {
  content: "\e90a";
}
.icon-bookmark-on:before {
  content: "\e90b";
}
.icon-dashboard:before {
  content: "\e909";
}
.icon-close-s:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-arrow-circle-s:before {
  content: "\e901";
}
.icon-arrow-circle:before {
  content: "\e900";
}
.icon-arrow:before {
  content: "\e902";
}
.icon-info:before {
  content: "\e903";
}
.icon-news:before {
  content: "\e904";
}
.icon-pdf:before {
  content: "\e905";
}
.icon-upload:before {
  content: "\e906";
}
